// src/components/home/HomePageTest.jsx
import React, { useEffect, useState } from "react";
import { app, authentication } from "@microsoft/teams-js";

// MSAL
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../azure_auth/authConfig";

// Graph call
import { callMsGraph } from "../../azure_auth/graph";

// Redux
import { connect } from "react-redux";
import { addUserThunk, getUserThunk } from "../../redux/users/users.thunk";

// MUI & components
import { Button, CircularProgress } from "@mui/material";
import SurveySlackIndex from "../survey/survey-index/survey-slack-index";
import employee from "../../assets/employee.gif";
import { useEmailContext } from "../../redux/EmailContext";
import { getUserService } from "../../services/user.service";

const HomePageTest = (props) => {
  /**
   * If you have i18n translation, we assume t is passed in props:
   * <HomePageTest t={t} />
   */
  const { t } = props;

  const { instance, accounts, inProgress } = useMsal();

  // Are we inside Microsoft Teams?
  const [isInTeams, setIsInTeams] = useState(false);

  // The Teams loginHint or userPrincipalName from context
  const [teamsLoginHint, setTeamsLoginHint] = useState(null);

  // We'll store an access token if we successfully sign in with Graph
  const [accessToken, setAccessToken] = useState(null);

  /**
   * userData: If non-null => "logged in".
   * - If we do MSAL + Graph => we store the Graph data.
   * - If we fallback to context => we store a minimal object (with .fallback=true).
   */
  const [userData, setUserData] = useState(null);

  // We'll store any error messages
  const [error, setError] = useState(null);
  const { setEmailContext, setIsLoggedInTeams, setUserDataContext } = useEmailContext(); 
  
  console.log("Entering HomePageTest");
  console.log("MSAL Accounts:", accounts);

  // 1) Detect if we're in Teams + retrieve context
  useEffect(() => {
    app.initialize()
      .then(() => {
        console.log("Running inside Microsoft Teams.");
        setIsInTeams(true);
        setIsLoggedInTeams(true);
        return app.getContext();
      })
      .then((context) => {
        console.log("Teams context =>", context);
        const userObj = context?.user;
        if (userObj?.loginHint) {
          setTeamsLoginHint(userObj.loginHint);
        } else if (userObj?.userPrincipalName) {
          setTeamsLoginHint(userObj.userPrincipalName);
        }
      })
      .catch((err) => {
        console.log("Not running inside Microsoft Teams:", err);
        setIsInTeams(false);
        setIsLoggedInTeams(false);
      });
  }, []);

  // 2) If in Teams + have loginHint => attempt silent SSO
  useEffect(() => {
    if (isInTeams && teamsLoginHint) {
      console.log("Attempting ssoSilent() in Teams with loginHint =", teamsLoginHint);

      instance
        .ssoSilent({ ...loginRequest, loginHint: teamsLoginHint })
        .then((response) => {
          console.log("ssoSilent success (Teams):", response);
          setAccessToken(response.accessToken);
          fetchUserProfile(response.accessToken);
        })
        .catch((err) => {
          console.warn("ssoSilent failed in Teams:", err);
        });
    }
  }, [isInTeams, teamsLoginHint, instance]);

  // 3) Outside Teams + we have an account => attempt ssoSilent
  useEffect(() => {
    if (!isInTeams && accounts.length > 0 && accounts[0].username) {
      console.log("Attempting silent auth outside Teams...");
      instance
        .ssoSilent({ ...loginRequest, loginHint: accounts[0].username })
        .then((response) => {
          console.log("SSO Silent Success (outside Teams):", response);
          setAccessToken(response.accessToken);
          fetchUserProfile(response.accessToken);
        })
        .catch((err) => {
          console.warn("Silent auth failed outside Teams:", err);
          setError("Silent auth failed outside Teams");
        });
    }
  }, [isInTeams, accounts, instance]);

  // 4) Fetch user profile from Microsoft Graph
  const fetchUserProfile = async (token) => {
    try {
      const data = await callMsGraph(token);
      console.log("Graph user data =>", data);
      setUserData(data);

      // Check or create user in your backend via Redux
      const userEmail = data.mail || data.userPrincipalName;
      await props.getUserSlack(userEmail);
      setEmailContext(userEmail)
      if (!props.user) {
        console.log("User does not exist in DB => Creating...");
        const newUser = {
          firstName: data.givenName || "",
          lastName: data.surname || "",
          email: userEmail,
          id: data.id
        };
        await props.addUser(newUser);
        console.log("User saved in DB.");
      } else {
        console.log("User already exists =>", props.user);
      }
    } catch (err) {
      console.error("Error fetching user profile:", err);
      setError("Failed to retrieve user profile from Graph.");
    }
  };

  // ----------------------------------------------------------------
  // (A) signInStandard: attempt normal MSAL popup
  // ----------------------------------------------------------------
  const signInStandard = async () => {
    console.log("Attempting MSAL popup login...");
    const response = await instance.loginPopup(loginRequest);
    console.log("MSAL popup login success =>", response);

    setAccessToken(response.accessToken);
    await fetchUserProfile(response.accessToken);
  };

  // ----------------------------------------------------------------
  // (B) signInTeams: attempt the official Teams popup (auth-start.html)
  // ----------------------------------------------------------------
  const signInTeams = () => {
    return new Promise((resolve, reject) => {
      authentication.authenticate({
        url: window.location.origin + "/auth-start.html",
        width: 600,
        height: 535,
        successCallback: async (result) => {
          try {
            console.log("Auth in Teams popup =>", result);
            const parsed = JSON.parse(result);
            const { accessToken, idToken } = parsed;

            const tokenToUse = accessToken || idToken;
            setAccessToken(tokenToUse);

            if (accessToken) {
              await fetchUserProfile(accessToken);
            }
            resolve("Teams popup success");
          } catch (err) {
            console.error("Parsing error =>", err);
            reject("Error parsing auth response in successCallback.");
          }
        },
        failureCallback: (reason) => {
          console.error("Teams popup failed =>", reason);
          reject("Auth failed => " + reason);
        }
      });
    });
  };

  // ----------------------------------------------------------------
  // (C) signInContext: final fallback if popups fail
  // ----------------------------------------------------------------

  const signInContext = async () => {  
    try {  
        console.log("Falling back to context-based approach...");  
        const fallbackEmail = teamsLoginHint || "unknown_user@context.com";  
        setEmailContext(fallbackEmail);  

        const userDataFromService = await getUserService(fallbackEmail);  
        console.log("User data from service:", userDataFromService);  

        setUserData(userDataFromService); // Update local state with user data from service  
        setUserDataContext(userDataFromService);
        console.log("complete data is : ", userDataFromService);  

        // Optionally, use this user data in Redux or a global state management solution  
        const fallbackUser = {  
            displayName: userDataFromService.firstName + ' ' + userDataFromService.lastName,  
            mail: fallbackEmail,  
            id: userDataFromService.id,  
            fallback: true  
        };  
        setUserData(fallbackUser);  
        console.log("Context fallback =>", fallbackUser);  

    } catch (error) {  
        console.error("Error with fallback sign-in:", error);  
        setError("An error occurred while retrieving user data");  
    }  
};  

  // ----------------------------------------------------------------
  // (D) signInFlow: tries MSAL popup -> if fail, tries Teams popup -> if fail, context fallback
  // ----------------------------------------------------------------
  const signInFlow = async () => {

    try {
      /*if (isInTeams) {
          signInContext();
      } else {
        // Not in Teams => fallback to context
        await signInStandard();
      }*/
      await signInStandard();
      // If success, done.
    } catch (msalError) {
      console.warn("MSAL popup failed =>", msalError);
      setError("MSAL popup failed => " + msalError);

      if (isInTeams) {
        try {
          signInContext();
          return ;
          await signInTeams();
        } catch (teamsError) {
          console.warn("Teams popup also failed =>", teamsError);
          setError("Teams popup failed => " + teamsError);

          // Final fallback => context
          signInContext();
        }
      } else {
        // Not in Teams => fallback to context
        signInContext();
      }
    }
  };

  // We'll consider the user "logged in" if userData != null
  const isUserLoggedIn = !!userData;
  console.log("isUserLoggedIn data is: ", isUserLoggedIn)
  console.log("userData data is: ", userData)
  
  return (
    <div className="row h-100 mt-5">
      {isUserLoggedIn ? (
        // If we have userData => show main content
        <div className="row">
          <div className="col-md-12">
            {userData?.fallback && 1 > 2 &&  (
              <div style={{ marginBottom: "1rem" }}>
                <h3>Context-based fallback user</h3>
                <p><strong>Name:</strong> {userData.displayName}</p>
                <p><strong>Email:</strong> {userData.mail}</p>
                <p>(No MSAL token, no Graph call here)</p>
              </div>
            )}

            {/* ALWAYS show SurveySlackIndex, even if fallback */}
            <SurveySlackIndex />
          </div>
        </div>
      ) : (
        // If userData is null => show sign-in UI
        <div className="row h-100 mt-5">
          <div className="col-md-6">
            {/* We show your translations from i18n */}
            <p className="fs-lg-1">
              <b>{t("home.title")}</b>
            </p>
            <p className="fs-5">
              {t("home.description")}
            </p>

            {inProgress !== "none" && (
              <p>Processing login... {inProgress}</p>
            )}

            {error && (
              <p style={{ color: "red" }}>{error}</p>
            )}

            {/* Single sign-in button */}
            <Button
              variant="outlined"
              color="primary"
              size="large"
              className="mt-2"
              onClick={signInFlow}
            >
              Sign in
            </Button>
          </div>

          <div className="col-md-6 text-center">
            <img src={employee} alt="employee" width="100%" />
          </div>
        </div>
      )}
    </div>
  );
};

// Connect to Redux
const mapStateToProps = (state) => ({
  user: state.users.user
});

const mapDispatchToProps = (dispatch) => ({
  getUserSlack: (email) => dispatch(getUserThunk(email)),
  addUser: (obj) => dispatch(addUserThunk(obj))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageTest);
