import React, { Suspense, useEffect, useState } from "react";  
import ReactDOM from "react-dom";  
import "./index.css";  
import "bootstrap/dist/css/bootstrap.min.css";  
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";  
  
// Redux  
import { Provider } from "react-redux";  
import store from "./redux/store";  
  
// MSAL  
import { PublicClientApplication } from "@azure/msal-browser";  
import { MsalProvider } from "@azure/msal-react";  
import { ExtendedMsalProvider } from "./redux/ExtendedMsalProvider";  
//import { msalConfig } from "./azure_auth/authConfig";  
import { msalConfig } from "./components/home/loginRequestText";  
  
// MUI  
import { ThemeProvider, CircularProgress } from "@mui/material";  
import { theme } from "./assets/styles/theme";  
  
// Router  
import { BrowserRouter } from "react-router-dom";  
  
// Para notificaciones Toastr  
import ReduxToastr from "react-redux-toastr";  
  
// Tu componente principal  
import App from "./App";  
  
// (Opcional) i18n, si usas  
import "./i18n";  
  
import reportWebVitals from "./reportWebVitals";  
import { EmailProvider } from "./redux/EmailContext"; // Asegúrate de que la ruta sea correcta  
  
function Root() {  
  // Creamos la instancia de MSAL UNA sola vez  
  const [msalInstance] = useState(() => new PublicClientApplication(msalConfig));  
  const [msalInitialized, setMsalInitialized] = useState(false);  
  
  // Llamamos a initialize() para habilitar .accounts y .inProgress  
  useEffect(() => {  
    msalInstance  
      .initialize()  
      .then(() => {  
        console.log("MSAL instance initialized");  
        setMsalInitialized(true);  
      })  
      .catch((err) => {  
        console.error("Error initializing MSAL:", err);  
      });  
  }, [msalInstance]);  
  
  if (!msalInitialized) {  
    // Mostrar un fallback mientras MSAL se inicializa  
    return (  
      <div className="text-center col-12" style={{ marginTop: 50 }}>  
        <p>Loading MSAL...</p>  
        <CircularProgress />  
      </div>  
    );  
  }  
  
  // Una vez inicializado => renderizamos la aplicación  
  return (  
    <React.StrictMode>  
      <MsalProvider instance={msalInstance}>  
        <EmailProvider> 
          <Provider store={store}>  
            <BrowserRouter>  
              <ThemeProvider theme={theme}>  
                <Suspense  
                  fallback={  
                    <div className="text-center col-12">  
                      <p>Loading...</p>  
                      <CircularProgress />  
                    </div>  
                  }  
                >  
                  <App />  
                </Suspense>  
              </ThemeProvider>  
              <ReduxToastr  
                timeOut={4000}  
                newestOnTop={false}  
                preventDuplicates  
                position="top-right"  
                transitionIn="fadeIn"  
                transitionOut="fadeOut"  
                closeOnToastrClick  
              />  
            </BrowserRouter>  
          </Provider>  
        </EmailProvider>  
      </MsalProvider>  
    </React.StrictMode>  
  );  
}  
  
ReactDOM.render(<Root />, document.getElementById("root"));  
  
// Opcional, métricas  
reportWebVitals();  