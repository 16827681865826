// src/App.js
import React from "react";
import { Box, Container, Toolbar } from "@mui/material";
import NavBar from "./components/layouts/NavBar";
import AppRouting from "./app-routing";

class AppClass extends React.Component {
  render() {
    return (
      <div className="min-vh-100">
        <NavBar />
        <Box className="h-100">
          <Toolbar />
          <Container className="mt-md-5">
            {/* 
              Sólo renderiza las rutas, donde una de ellas es HomePageTest
            */}
            <AppRouting />
          </Container>
        </Box>
      </div>
    );
  }
}

export default AppClass;
