import React from 'react';  
import { useMsal } from "@azure/msal-react";  
import { useEmailContext } from '../redux/EmailContext';

const AuthenticationWrapper = ({ children }) => {  
  const { accounts } = useMsal();  
  const { isLoggedInTeams, userDataContext } = useEmailContext();  
  
  // Logs para depuración  
  console.log("MSAL Accounts:", accounts);  
  console.log("Logged in Teams:", isLoggedInTeams);  
  console.log("User Data from Context:", userDataContext);  
  
  // Determinar autenticación mediante MSAL o contexto  
  const isAuthenticated = accounts.length > 0 || (isLoggedInTeams && userDataContext !== "");  
  
  console.log("Is Authenticated:", isAuthenticated);  
  
  return (  
    <div>  
      {isAuthenticated ? (  
        <div>{children}</div>  
      ) : (  
        <div>Please Sign In to View the Dashboard</div>  
      )}  
    </div>  
  );  
};  
  
export default AuthenticationWrapper;  