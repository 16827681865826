// src/components/home/HomePage.js
import React, { useState, useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { app } from "@microsoft/teams-js";

import { 
  AuthenticatedTemplate, 
  UnauthenticatedTemplate, 
  useMsal 
} from "@azure/msal-react";
import { loginRequest } from "../../azure_auth/authConfig";
import { callMsGraph } from "../../azure_auth/graph";

import { connect } from "react-redux";
import { addUserThunk } from "../../redux/users/users.thunk";

// MUI
import { Alert, Avatar, Button, CircularProgress } from "@mui/material";
import { capitalize } from "@mui/material";
import teamsLogo from "../../assets/Microsoft_Office_Teams.png";
import employee from "../../assets/employee.gif";
import "./home.css";

// Surveys
import SurveySlackIndex from "../survey/survey-index/survey-slack-index";

// Botón para iniciar sesión (popup fuera de Teams)
import { SignInButton } from "../../azure_auth/components/SignInButton";

function HomePage(props) {
    console.log("---------------- home page")
  const { instance, accounts, inProgress } = useMsal();

  // Estado para saber si la app se ejecuta dentro de Teams
  const [isInTeams, setIsInTeams] = useState(false);
  // Estado para indicar que se requiere relogin (por error en acquireTokenSilent)
  const [loginRequired, setLoginRequired] = useState(false);

  // Al montar, detectamos si estamos embebidos en Teams

  useEffect(() => {
    async function initTeams() {
      try {
        // Con v2, ya no necesitas “if (window.parent !== window)”
        // Basta con intentar "app.initialize()" y si falla, asumes que no estás en Teams
        await app.initialize();
        console.log("Running inside Microsoft Teams.");
        setIsInTeams(true);
      } catch (error) {
        console.log("Not running inside Microsoft Teams (or error).", error);
        setIsInTeams(false);
      }
    }
  
    initTeams();
  }, []);
  

  // Cada vez que cambia la cuenta, intentamos obtener un token silencioso y llamar a Graph para registrar el usuario
  useEffect(() => {
    async function fetchUserProfile() {
      if (accounts.length > 0) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });
          const userGraph = await callMsGraph(response.accessToken);
          console.log("GRAPH user data:", userGraph);

          // Si el usuario no existe en Redux, lo agregamos (registración en BD)
          if (!props.user) {
            const newUser = {
              lastName: userGraph.surname,
              firstName: userGraph.givenName,
              email: userGraph.userPrincipalName || userGraph.mail,
              id: userGraph.id,
              tenantId: accounts[0].tenantId,
            };
            await props.addUser(newUser);
          }
        } catch (error) {
          console.error("Silent token error => login needed:", error);
          setLoginRequired(true);
        }
      }
    }
    fetchUserProfile();
  }, [accounts, instance, props]);

  // 1) Método de inicio de sesión con popup de Teams
  const signInTeams = () => {
    if (!isInTeams) {
      console.warn("No se detecta que se esté ejecutando dentro de Microsoft Teams");
      return;
    }
    microsoftTeams.authentication.authenticate({
      url: window.location.origin + "/auth-start.html",
      width: 600,
      height: 535,
      successCallback: (result) => {
        console.log("Teams popup success:", result);
        setLoginRequired(false);
      },
      failureCallback: (reason) => {
        console.error("Teams popup FAILED:", reason);
      },
    });
  };

  // 2) Método de inicio de sesión estándar (fuera de Teams)
  const signInStandard = async () => {
    try {
      console.log("Iniciando MSAL popup fuera de Teams...");
      await instance.loginPopup(loginRequest);
      setLoginRequired(false);
    } catch (error) {
      console.error("MSAL popup error:", error);
    }
  };

  return (
    <div>
      {/* Indicador mientras se procesa la autenticación */}
      {inProgress !== "none" && (
        <div className="text-center">
          <p>{`${capitalize(inProgress)} in progress...`}</p>
          <CircularProgress />
        </div>
      )}

      {/* Si el usuario está autenticado */}
      <AuthenticatedTemplate>
        {loginRequired && !isInTeams ? (
          <div style={{ margin: "2rem" }}>
            <p>Your session expired, please sign in again (outside Teams)</p>
            <SignInButton
              variant="outlined"
              startIcon={<Avatar src={teamsLogo} sx={{ width: 24, height: 24 }} />}
            >
              Sign in with Microsoft
            </SignInButton>
          </div>
        ) : (
          <div style={{ marginTop: "2rem" }}>
            <SurveySlackIndex />
          </div>
        )}
      </AuthenticatedTemplate>

      {/* Si el usuario NO está autenticado */}
      <UnauthenticatedTemplate>
        <div className="row h-100 mt-5">
          <div className="col-md-6">
            <h2>Welcome to your multi-tenant app</h2>
            <p className="fs-5">
              A single platform to engage with your team members, cultivate trust, etc...
            </p>
            {isInTeams ? (
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={signInTeams}
              >
                Sign In for Teams
              </Button>
            ) : (
              <SignInButton
                variant="outlined"
                color="primary"
                startIcon={<Avatar src={teamsLogo} sx={{ width: 24, height: 24 }} />}
              >
                Sign in with Microsoft
              </SignInButton>
            )}
          </div>
          <div className="col-md-6 text-center">
            <img width="100%" src={employee} alt="Employee" />
          </div>
        </div>
      </UnauthenticatedTemplate>

      {props.lastUser && (
        <Alert severity="success" sx={{ width: "100%" }}>
          Success!, your app was successfully installed
        </Alert>
      )}
    </div>
  );
}

// Mapeo de Redux
const mapStateToProps = (state) => ({
  user: state.users.user,
  lastUser: state.users.lastUser,
});

const mapDispatchToProps = (dispatch) => ({
  addUser: (obj) => dispatch(addUserThunk(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
