import { setupLocale } from "survey-creator-core";

export var slStrings = {
  // survey templates
  survey: {
    edit: "Uredi",
    externalHelpLink: "Glej in se nauči, kako ustvariti ankete.",
    externalHelpLinkUrl: "https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber",
    dropQuestion: "Prosim, postavite vprašanje tukaj iz Orodjarne.",
    addLogicItem: "Ustvarite pravilo za prilagoditev toka ankete.",
    copy: "Kopiraj",
    duplicate: "Dvojnik",
    addToToolbox: "Dodaj v orodje",
    deletePanel: "Izbriši ploščo",
    deleteQuestion: "Izbriši vprašanje",
    convertTo: "Pretvori v",
    drag: "Povlecite element",
  },
  // Creator tabs
  tabs: {
    preview: "Predogled",
    theme: "Teme",
    translation: "Prevodi",
    designer: "Oblikovalec",
    editor: "Urejevalnik JSON",
    logic: "Logika"
  },
  // Question types
  qt: {
    default: "Privzeto",
    checkbox: "Potrditvena polja",
    comment: "Dolgo besedilo",
    imagepicker: "Izbirnik slik",
    ranking: "Uvrstitev",
    image: "Slika",
    dropdown: "Spustni meni",
    tagbox: "Spustni meni za več izbir",
    file: "Nalaganje datoteke",
    html: "HTML",
    matrix: "Matrika z eno izbiro",
    matrixdropdown: "Multi-Select Matrix",
    matrixdynamic: "Dinamična matrica",
    multipletext: "Več besedilnih polj",
    panel: "Panel",
    paneldynamic: "Dinamična plošča",
    radiogroup: "Skupina radijskih gumbov",
    rating: "Ocenjevalna lestvica",
    text: "Enovrstični vnos",
    boolean: "Da/Ne (logično)",
    expression: "Izraz (samo za branje)",
    signaturepad: "Podpis",
    buttongroup: "Skupina gumbov"
  },
  toolboxCategories: {
    general: "Splošno",
    choice: "Izbirna vprašanja",
    text: "Vprašanja za vnos besedila",
    containers: "Zabojniki",
    matrix: "Matrična vprašanja",
    misc: "Misc"
  },
  // Strings in SurveyJS Creator
  ed: {
    defaultLocale: "Privzeto ({0})",
    survey: "Anketa",
    settings: "Nastavitve",
    settingsTooltip: "Odprite nastavitve",
    surveySettings: "Nastavitve ankete",
    surveySettingsTooltip: "Nastavitve ankete",
    themeSettings: "Nastavitve teme",
    themeSettingsTooltip: "Nastavitve teme",
    creatorSettingTitle: "Nastavitve ustvarjalca",
    showPanel: "Pokaži ploščo",
    hidePanel: "Skrij ploščo",
    prevSelected: "Izberite prejšnji",
    nextSelected: "Izberite Naprej",
    prevFocus: "Osredotoči se na prejšnje",
    nextFocus: "Osredotočite se na naslednjega",
    surveyTypeName: "Anketa",
    pageTypeName: "Stran",
    panelTypeName: "Panel",
    questionTypeName: "vprašanje",
    columnTypeName: "Stolpec",
    addNewPage: "Dodaj novo stran",
    moveRight: "Pomaknite se v desno",
    moveLeft: "Pomaknite se v levo",
    deletePage: "Izbriši stran",
    editPage: "Uredi stran",
    edit: "Uredi",
    newPageName: "stran",
    newQuestionName: "vprašanje",
    newPanelName: "panel",
    newTextItemName: "tekst",
    defaultV2Theme: "Privzeto",
    modernTheme: "Moderno",
    defaultTheme: "Privzeto (podedovano)",
    testSurveyAgain: "Spet predogled ankete",
    testSurveyWidth: "Širina ankete: ",
    navigateToMsg: "Morali ste navigirati do",
    saveSurvey: "Shrani anketo",
    saveSurveyTooltip: "Shrani anketo",
    saveTheme: "Shrani temo",
    saveThemeTooltip: "Shrani temo",
    jsonHideErrors: "Skrij napake",
    jsonShowErrors: "Pokaži napake",
    undo: "Razveljavi",
    redo: "Ponovi",
    undoTooltip: "Razveljavi zadnjo spremembo",
    redoTooltip: "Ponovite spremembo",
    expandAllTooltip: "Razširi vse",
    collapseAllTooltip: "Collapse All",
    zoomInTooltip: "Povečaj",
    zoom100Tooltip: "100%",
    zoomOutTooltip: "Pomanjšaj",
    lockQuestionsTooltip: "Zakleni stanje razširitve/strnitve za vprašanja",
    showMoreChoices: "Pokaži več",
    showLessChoices: "Pokaži manj",
    copy: "Kopiraj",
    cut: "Izreži",
    paste: "Prilepi",
    copyTooltip: "Kopiraj izbiro v odložišče",
    cutTooltip: "Izreži izbiro v odložišče",
    pasteTooltip: "Prilepi iz odložišča",
    options: "Možnosti",
    generateValidJSON: "Ustvari veljaven JSON",
    generateReadableJSON: "Ustvari berljiv JSON",
    toolbox: "Orodjarna",
    "property-grid": "Lastnosti",
    toolboxFilteredTextPlaceholder: "Vnesite za iskanje...",
    toolboxNoResultsFound: "Ni najdenih rezultatov",
    propertyGridFilteredTextPlaceholder: "Vnesite za iskanje...",
    propertyGridNoResultsFound: "Ni najdenih rezultatov",
    propertyGridPlaceholderTitle: "Začnite s konfiguracijo obrazca",
    propertyGridPlaceholderDescription: "Kliknite katero koli ikono kategorije za raziskovanje nastavitev ankete. Dodatne nastavitve bodo na voljo, ko dodate element ankete na oblikovno površino.",
    correctJSON: "Prosimo, popravite JSON.",
    surveyResults: "Rezultati ankete",
    surveyResultsTable: "Kot tabela",
    surveyResultsJson: "Kot JSON",
    resultsTitle: "Naslov vprašanja",
    resultsName: "Ime vprašanja",
    resultsValue: "Vrednost odgovora",
    resultsDisplayValue: "Prikazana vrednost",
    modified: "Spremenjeno",
    saving: "Shranjevanje",
    saved: "Shranjeno",
    propertyEditorError: "Napaka",
    saveError: "Napaka! Vsebina urejevalnika ni shranjena.",
    translationPropertyGridTitle: "Jezikovne nastavitve",
    themePropertyGridTitle: "Nastavitve teme",
    addLanguageTooltip: "Dodajanje jezika",
    translationLanguages: "Jeziki",
    translationDeleteLanguage: "Ste prepričani, da želite izbrisati vse nize za ta jezik?",
    translationAddLanguage: "Izberite jezik za prevod",
    translationShowAllStrings: "Vsi nizi",
    translationShowUsedStringsOnly: "Samo uporabljeni nizi",
    translationShowAllPages: "Vse strani",
    translationNoStrings: "Ni nizov za prevod. Spremenite filter.",
    translationExportToSCVButton: "Izvozi v CSV",
    translationImportFromSCVButton: "Uvozi iz CSV",
    translateUsigAI: "Samodejno prevedi vse",
    translateUsigAIFrom: "Prevedi iz: ",
    translationDialogTitle: "Neprevedeni nizi",
    translationMergeLocaleWithDefault: "Združi {0} s privzetim jezikom",
    translationPlaceHolder: "Prevod...",
    translationSource: "Vir: ",
    translationTarget: "Cilj: ",
    translationYouTubeNotSupported: "Povezave do YouTuba niso podprte.",
    themeExportButton: "Izvoz",
    themeImportButton: "Uvoz",
    surveyJsonExportButton: "Izvoz",
    surveyJsonImportButton: "Uvoz",
    surveyJsonCopyButton: "Kopiraj v odložišče",
    themeResetButton: "Ponastavi nastavitve teme na privzete",
    themeResetConfirmation: "Ali res želite ponastaviti temo? Vse vaše prilagoditve bodo izgubljene.",
    themeResetConfirmationOk: "Da, ponastavi temo",
    bold: "Krepko",
    italic: "Ležeče",
    underline: "Podčrtano",
    addNewQuestion: "Dodaj vprašanje",
    selectPage: "Izberite stran...",
    carryForwardChoicesCopied: "Možnosti so kopirane iz",
    choicesLoadedFromWebText: "Možnosti so naložene iz spletne storitve.",
    choicesLoadedFromWebLinkText: "Pojdi na nastavitve",
    choicesLoadedFromWebPreviewTitle: "Predogled naloženih možnosti izbire",
    htmlPlaceHolder: "Tukaj bo HTML vsebina.",
    panelPlaceHolder: "Sem povlecite vprašanje iz orodjarne.",
    surveyPlaceHolder: "Anketa je prazna. Povlecite element iz orodjarne ali kliknite spodnji gumb.",
    pagePlaceHolder: "Stran je prazna. Povlecite element iz orodjarne ali kliknite spodnji gumb.",
    imagePlaceHolder: "Povlecite in spustite sliko sem ali kliknite spodnji gumb in izberite sliko za nalaganje.",
    surveyPlaceHolderMobile: "Kliknite gumb \"Dodaj vprašanje\" spodaj, da začnete ustvarjati svoj obrazec.",
    surveyPlaceholderTitle: "Vaš obrazec je prazen",
    surveyPlaceholderTitleMobile: "Vaš obrazec je prazen",
    surveyPlaceholderDescription: "Povlecite element iz orodjarne ali kliknite spodnji gumb.",
    surveyPlaceholderDescriptionMobile: "Povlecite element iz orodjarne ali kliknite spodnji gumb.",
    previewPlaceholderTitle: "Ni predogleda",
    previewPlaceholderTitleMobile: "Ni predogleda",
    previewPlaceholderDescription: "Anketa ne vsebuje nobenih vidnih elementov.",
    previewPlaceholderDescriptionMobile: "Anketa ne vsebuje nobenih vidnih elementov.",
    translationsPlaceholderTitle: "Ni nizov za prevod",
    translationsPlaceholderTitleMobile: "Ni nizov za prevod",
    translationsPlaceholderDescription: "Dodajte elemente v svoj obrazec ali spremenite filter nizov v orodni vrstici.",
    translationsPlaceholderDescriptionMobile: "Dodajte elemente v svoj obrazec ali spremenite filter nizov v orodni vrstici.",
    pagePlaceHolderMobile: "Kliknite gumb \"Dodaj vprašanje\" spodaj, da dodate nov element na stran.",
    panelPlaceHolderMobile: "Kliknite gumb \"Dodaj vprašanje\" spodaj, da dodate nov element v ploščo.",
    imagePlaceHolderMobile: "Kliknite spodnji gumb in izberite sliko za nalaganje",
    imageChooseImage: "Izberite sliko",
    addNewTypeQuestion: "Dodaj {0}", //{0} is localizable question type
    chooseLogoPlaceholder: "[LOGOTIP]",
    choices_Item: "Element ",
    selectFile: "Izberite datoteko",
    removeFile: "Odstrani datoteko",
    lg: {
      addNewItem: "Dodaj novo pravilo",
      empty_tab: "Ustvarite pravilo za prilagoditev poteka ankete.",
      logicPlaceholderTitle: "Ni logičnih pravil",
      logicPlaceholderTitleMobile: "Ni logičnih pravil",
      logicPlaceholderDescription: "Ustvarite pravilo za prilagoditev poteka ankete.",
      logicPlaceholderDescriptionMobile: "Ustvarite pravilo za prilagoditev poteka ankete.",
      page_visibilityName: "Prikaži/skrij stran",
      page_enableName: "Omogoči/onemogoči stran",
      page_requireName: "Nastavi stran kot obvezno",
      panel_visibilityName: "Prikaži/skrij ploščo",
      panel_enableName: "Omogoči/onemogoči ploščo",
      panel_requireName: "Nastavi ploščo kot obvezno",
      question_visibilityName: "Prikaži/skrij vprašanje",
      question_enableName: "Omogoči/onemogoči vprašanje",
      question_requireName: "Nastavi vprašanje kot obvezno",
      question_resetValueName: "Ponastavi vrednost vprašanja",
      question_setValueName: "Nastavi vrednost vprašanja",
      column_visibilityName: "Prikaži/skrij stolpec",
      column_enableName: "Omogoči/onemogoči stolpec",
      column_requireName: "Nastavi stolpec kot obvezen",
      column_resetValueName: "Ponastavi vrednost stolpca",
      column_setValueName: "Nastavi vrednost stolpca",
      trigger_completeName: "Zaključi anketo",
      trigger_setvalueName: "Nastavi odgovor",
      trigger_copyvalueName: "Kopiraj odgovor",
      trigger_skipName: "Preskoči na vprašanje",
      trigger_runExpressionName: "Izvedi izraz",
      completedHtmlOnConditionName: "Nastavi vsebino strani \"Hvala\"",
      page_visibilityDescription: "Stran postane vidna, ko logični izraz vrne true. V nasprotnem primeru ostane skrita.",
      panel_visibilityDescription: "Plošča postane vidna, ko logični izraz vrne true. V nasprotnem primeru ostane skrita.",
      panel_enableDescription: "Plošča in vsi elementi v njej postanejo omogočeni, ko logični izraz vrne true. V nasprotnem primeru ostanejo onemogočeni.",
      question_visibilityDescription: "Vprašanje postane vidno, ko logični izraz vrne true. V nasprotnem primeru ostane skrito.",
      question_enableDescription: "Vprašanje postane omogočeno, ko logični izraz vrne true. V nasprotnem primeru ostane onemogočeno.",
      question_requireDescription: "Vprašanje postane obvezno, ko logični izraz vrne true.",
      trigger_completeDescription: "Ko logični izraz vrne true, se anketa zaključi in uporabnik vidi stran \"Hvala\".",
      trigger_setvalueDescription: "Ko se spremenijo vrednosti vprašanj v logičnem izrazu in izraz vrne true, se določena vrednost dodeli izbranemu vprašanju.",
      trigger_copyvalueDescription: "Ko se spremenijo vrednosti vprašanj v logičnem izrazu in izraz vrne true, se vrednost izbranega vprašanja kopira v drugo vprašanje.",
      trigger_skipDescription: "Ko logični izraz vrne true, anketa skoči na izbrano vprašanje.",
      trigger_runExpressionDescription: "Ko logični izraz vrne true, anketa izvede drug izraz. Po želji se rezultat lahko dodeli izbranemu vprašanju.",
      completedHtmlOnConditionDescription: "Če logični izraz vrne true, stran \"Hvala\" prikaže določeno vsebino.",
      itemExpressionText: "Ko izraz: '{0}' vrne true", //{0} - the expression
      itemEmptyExpressionText: "Novo pravilo",
      page_visibilityText: "naredi stran {0} vidno", //{0} page name
      panel_visibilityText: "naredi ploščo {0} vidno", //{0} panel name
      panel_enableText: "omogoči ploščo {0}", //{0} panel name
      question_visibilityText: "naredi vprašanje {0} vidno", //{0} question name
      question_enableText: "omogoči vprašanje {0}", //{0} question name
      question_requireText: "naredi vprašanje {0} obvezno", //{0} question name
      question_resetValueText: "ponastavi vrednost vprašanja: {0}", //{0} question name.
      question_setValueText: "dodeli vrednost: {1} vprašanju: {0}",
      column_visibilityText: "naredi stolpec {0} vprašanja {1} viden", //{0} column name, {1} question name
      column_enableText: "omogoči stolpec {0} vprašanja {1}", //{0} column name, {1} question name
      column_requireText: "naredi stolpec {0} vprašanja {1} obvezen", //{0} column name, {1} question name
      column_resetValueText: "ponastavi vrednost celice v stolpcu: {0}", //{0} column name
      column_setValueText: "dodeli vrednost celice: {1} stolpcu: {0}", //{0} column name and {1} setValueExpression
      setValueExpressionPlaceholder: "Izraz, katerega rezultat bo dodeljen ciljnemu vprašanju.",
      trigger_completeText: "anketa je zaključena",
      trigger_setvalueText: "nastavi v vprašanje: {0} vrednost {1}", //{0} question name, {1} setValue
      trigger_setvalueEmptyText: "počisti vrednost vprašanja: {0}", //{0} question name
      trigger_copyvalueText: "kopiraj v vprašanje: {0} vrednost iz vprašanja {1}", //{0} and {1} question names
      trigger_skipText: "anketa preskoči na vprašanje {0}", //{0} question name
      trigger_runExpressionText1: "izvedi izraz: '{0}'", //{0} the expression
      trigger_runExpressionText2: " in nastavi njegov rezultat v vprašanje: {0}", //{0} question name
      completedHtmlOnConditionText: "prikaži prilagojeno besedilo za stran \"Hvala\".",
      showAllQuestions: "Vsa vprašanja",
      showAllActionTypes: "Vse vrste dejanj",
      conditions: "Pogoj(i)",
      actions: "Dejanje(a)",
      expressionEditorTitle: "Določite pogoj(e)",
      actionsEditorTitle: "Določite dejanje(a)",
      deleteAction: "Izbriši dejanje",
      addNewAction: "Dodaj dejanje",
      selectedActionCaption: "Izberite dejanje...",
      expressionInvalid: "Logični izraz je prazen ali neveljaven. Prosimo, popravite ga.",
      noActionError: "Prosimo, dodajte vsaj eno dejanje.",
      actionInvalid: "Prosimo, popravite težave v dejanjih.",
      uncompletedRule_title: "Logična pravila niso dokončana",
      uncompletedRule_text: "Nekatera logična pravila niso dokončana. Če zdaj zapustite zavihek, bodo spremembe izgubljene. Ali želite zapustiti zavihek brez dokončanja sprememb?",
      uncompletedRule_apply: "Da",
      uncompletedRule_cancel: "Ne, želim dokončati pravila"
    }
  },
  // Property Editors
  pe: {
    panel: {
      name: "Ime plošče",
      title: "Naslov plošče",
      description: "Opis plošče",
      visibleIf: "Naredi ploščo vidno, če",
      requiredIf: "Naredi ploščo obvezno, če",
      questionsOrder: "Vrstni red vprašanj v odboru",
      page: "Premakni ploščo na stran",
      startWithNewLine: "Prikaži ploščo v novi vrstici",
      state: "Stanje strnjevanja plošče",
      width: "Širina plošče v vrstici",
      minWidth: "Minimalna širina plošče",
      maxWidth: "Maksimalna širina plošče",
      showNumber: "Oštevilči to ploščo"
    },
    paneldynamic: {
      name: "Ime plošče",
      title: "Naslov plošče",
      description: "Opis plošče",
      visibleIf: "Naredi ploščo vidno, če",
      requiredIf: "Naredi ploščo obvezno, če",
      page: "Premakni ploščo na stran",
      startWithNewLine: "Prikaži ploščo v novi vrstici",
      state: "Stanje strnjevanja plošče",
      width: "Širina plošče v vrstici",
      minWidth: "Minimalna širina plošče",
      maxWidth: "Maksimalna širina plošče",
      confirmDelete: "Potrdite brisanje plošče",
      templateDescription: "Vzorec opisa plošče",
      templateTitle: "Vzorec naslova plošče",
      noEntriesText: "Prazno besedilo plošče",
      templateTabTitle: "Vzorec naslova zavihka",
      tabTitlePlaceholder: "Zamenjava za naslov zavihka",
      templateVisibleIf: "Naredi posamezno ploščo vidno, če",
      hideNumber: "Skrivanje številke plošče",
      titleLocation: "Poravnava naslova plošče",
      descriptionLocation: "Poravnava opisa plošče",
      templateTitleLocation: "Poravnava naslova vprašanja",
      templateErrorLocation: "Poravnava sporočila o napaki",
      newPanelPosition: "Nova lokacija plošče",
      showRangeInProgress: "Prikaži vrstico napredka",
      showProgressBar: "Prikaz vrstice napredovanja",
      progressBarLocation: "Poravnava vrstice napredovanja",
      keyName: "Prepreči podvojene odgovore v naslednjem vprašanju"
    },
    question: {
      name: "Ime vprašanja",
      title: "Naslov vprašanja",
      description: "Opis vprašanja",
      visibleIf: "Naredi vprašanje vidno, če",
      requiredIf: "Naredi vprašanje obvezno, če",
      page: "Premakni vprašanje na stran",
      state: "Stanje strnjevanja vprašalnika",
      hideNumber: "Skrivanje številke vprašanja",
      titleLocation: "Poravnava naslova vprašanja",
      descriptionLocation: "Poravnava opisa vprašanja",
      errorLocation: "Poravnava sporočila o napaki",
      indent: "Povečaj notranji zamik",
      width: "Širina vprašanja v vrstici",
      minWidth: "Minimalna širina vprašanja",
      maxWidth: "Maksimalna širina vprašanja",
      textUpdateMode: "Posodobi vrednost vnosnega polja"
    },
    signaturepad: {
      signatureWidth: "Širina območja za podpis",
      signatureHeight: "Višina območja za podpis",
      signatureAutoScaleEnabled: "Samodejno prilagodi velikost območja za podpis",
      showPlaceholder: "Prikaži besedilo vnaprej določenega mesta v območju za podpis",
      placeholder: "Besedilo vnaprej določenega mesta",
      placeholderReadOnly: "Besedilo vnaprej določenega mesta v načinu samo za branje ali v predoglednem načinu",
      allowClear: "Prikaži gumb za izbris v območju za podpis",
      penMinWidth: "Minimalna širina črte",
      penMaxWidth: "Maksimalna širina črte",
      penColor: "Barva črte"
    },
    comment: {
      rows: "Višina vnosnega polja (v vrsticah)"
    },
    showQuestionNumbers: "Oštevilčevanje vprašanj",
    questionStartIndex: "Vrsta indeksiranja vprašanj",
    expression: {
      name: "Ime izraza",
      title: "Naslov izraza",
      description: "Opis izraza",
      expression: "Izraz"
    },
    trigger: {
      expression: "Izraz"
    },
    calculatedvalue: {
      expression: "Izraz"
    },
    // survey templates
    survey: {
      title: "Naslov ankete",
      description: "Opis ankete"
    },
    page: {
      name: "Ime strani",
      title: "Naslov strani",
      description: "Opis strani",
      visibleIf: "Naredi stran vidno, če",
      requiredIf: "Naredi stran obvezno, če",
      timeLimit: "Časovni omejitev za dokončanje strani",
      questionsOrder: "Vrstni red vprašanj na strani"
    },
    matrixdropdowncolumn: {
      name: "Ime stolpca",
      title: "Naslov stolpca",
      isUnique: "Prepreči podvojene odgovore",
      width: "Širina stolpca",
      minWidth: "Minimalna širina stolpca",
      rows: "Višina vnosnega polja (v vrsticah)",
      visibleIf: "Naredi stolpec viden, če",
      requiredIf: "Naredi stolpec obvezen, če",
      showInMultipleColumns: "Vsaka možnost v ločenem stolpcu"
    },
    multipletextitem: {
      name: "Ime",
      title: "Naslov"
    },
    masksettings: {
      saveMaskedValue: "Shrani zakrito vrednost v rezultatih ankete"
    },
    patternmask: {
      pattern: "Vzorec vrednosti"
    },
    datetimemask: {
      min: "Minimalna vrednost",
      max: "Maksimalna vrednost"
    },
    numericmask: {
      allowNegativeValues: "Dovoli negativne vrednosti",
      thousandsSeparator: "Ločevalec tisočic",
      decimalSeparator: "Ločevalec decimalk",
      precision: "Natančnost vrednosti",
      min: "Minimalna vrednost",
      max: "Maksimalna vrednost"
    },
    currencymask: {
      prefix: "Predpona valute",
      suffix: "Zaimek valute"
    },
    imageHeight: "Višina območja za prikaz",
    imageWidth: "Širina območja za prikaz",
    valueName: "Povezovalni identifikator",
    rateDescriptionLocation: "Poravnava oznake",
    size: "Širina vnosa (v znakih)",
    cellErrorLocation: "Poravnava sporočila o napaki celice",
    enabled: "Omogočeno",
    disabled: "Onemogočeno",
    inherit: "Dedovanje",
    apply: "Uporabi",
    ok: "V redu",
    save: "Shrani",
    clear: "Počisti",
    saveTooltip: "Shrani",
    cancel: "Prekliči",
    set: "Nastavi",
    reset: "Ponastavi",
    change: "Spremeni",
    refresh: "Osveži",
    close: "Zapri",
    delete: "Izbriši",
    add: "Dodaj",
    addNew: "Dodaj novo",
    addItem: "Kliknite za dodajanje elementa...",
    removeItem: "Kliknite za odstranitev elementa...",
    dragItem: "Povlecite element",
    addOther: "Drugo",
    addSelectAll: "Izberi vse",
    addNone: "Noben",
    removeAll: "Odstrani vse",
    edit: "Uredi",
    back: "Vrni se brez shranjevanja",
    backTooltip: "Vrni se brez shranjevanja",
    saveAndBack: "Shrani in vrni se",
    saveAndBackTooltip: "Shrani in vrni se",
    doneEditing: "Končano",
    editChoices: "Uredi možnosti",
    showChoices: "Pokaži možnosti",
    move: "Premakni",
    empty: "<prazno>",
    emptyValue: "Vrednost je prazna",
    fastEntry: "Ročni vnos",
    fastEntryNonUniqueError: "Vrednost '{0}' ni edinstvena",
    fastEntryChoicesCountError: "Prosimo, omejite število možnosti od {0} do {1}",
    fastEntryChoicesMinCountError: "Vnesite vsaj {0} možnosti",
    fastEntryPlaceholder: "Vnesite seznam možnosti in njihovih ID-jev v naslednjem formatu:\n\nid|možnost\n\nID možnosti ni viden anketirancem in se lahko uporablja v pogojnih pravilih.",
    formEntry: "Vnos obrazca",
    testService: "Preizkusite storitev",
    itemSelectorEmpty: "Prosimo, izberite element",
    conditionActionEmpty: "Prosimo, izberite dejanje",
    conditionSelectQuestion: "Izberite vprašanje...",
    conditionSelectPage: "Izberite stran...",
    conditionSelectPanel: "Izberite panel...",
    conditionValueQuestionTitle: "Vnesite/izberite vrednost",
    aceEditorHelp: "Pritisnite ctrl+space za hint za dokončanje izraza",
    aceEditorRowTitle: "Trenutna vrstica",
    aceEditorPanelTitle: "Trenutni panel",
    showMore: "Za več podrobnosti preverite dokumentacijo",
    assistantTitle: "Na voljo vprašanja",
    cellsEmptyRowsColumns: "Morate imeti vsaj eno stolpec ali vrstico",
    showPreviewBeforeComplete: "Pregled pred oddajo",
    overridingPropertyPrefix: "Omogočeno s pogojem",
    resetToDefaultCaption: "Ponastavi",
    propertyIsEmpty: "Vnesite vrednost",
    propertyIsNoUnique: "Vnesite edinstveno vrednost",
    propertyNameIsNotUnique: "Vnesite edinstveno ime",
    propertyNameIsIncorrect: "Ne uporabljajte rezerviranih besed: \"item\", \"choice\", \"panel\", \"row\".",
    listIsEmpty: "Še nimate nobenih elementov",
    "listIsEmpty@choices": "Še nimate nobenih možnosti",
    "listIsEmpty@columns": "Še nimate nobenih stolpcev",
    "listIsEmpty@rows": "Še nimate nobenih vrstic",
    "listIsEmpty@validators": "Še nimate nobenih pravil za preverjanje",
    "listIsEmpty@calculatedValues": "Še nimate nobenih spremenljivk",
    "listIsEmpty@triggers": "Še nimate nobenih sprožilcev",
    "listIsEmpty@navigateToUrlOnCondition": "Še nimate nobenih povezav",
    "listIsEmpty@pages": "Še nimate nobenih strani",
    "addNew@choices": "Dodaj novo možnost",
    "addNew@columns": "Dodaj nov stolpec",
    "addNew@rows": "Dodaj novo vrstico",
    "addNew@validators": "Dodaj novo pravilo",
    "addNew@calculatedValues": "Dodaj novo spremenljivko",
    "addNew@triggers": "Dodaj nov sprožilec",
    "addNew@navigateToUrlOnCondition": "Dodaj novo URL",
    "addNew@pages": "Dodaj novo stran",
    expressionIsEmpty: "Izraz je prazen",
    value: "Vrednost",
    text: "Besedilo",
    rowid: "ID vrstice",
    imageLink: "Povezava do slike ali videa",
    columnEdit: "Uredi stolpec: {0}",
    itemEdit: "Uredi element: {0}",
    url: "URL",
    path: "Pot do podatkov",
    choicesbyurl: {
      url: "URL spletne storitve",
      valueName: "Pridobite vrednost za shranjevanje iz naslednje lastnosti"
    },
    titleName: "Pridobite vrednost za prikaz iz naslednje lastnosti",
    imageLinkName: "Pridobite URL-je datotek iz naslednje lastnosti",
    allowEmptyResponse: "Sprejmi prazne odgovore",
    titlePlaceholder: "Naslov",
    surveyTitlePlaceholder: "Naslov ankete",
    pageTitlePlaceholder: "Stran {num}",
    startPageTitlePlaceholder: "Začetna stran",
    descriptionPlaceholder: "Opis",
    surveyDescriptionPlaceholder: "Opis ankete",
    pageDescriptionPlaceholder: "Opis strani",
    textWrapEnabled: "Prelomi izbire",
    showOtherItem: "Omogoči možnost \"Drugo\"",
    otherText: "Preimenuj možnost \"Drugo\"",
    showNoneItem: "Omogoči možnost \"Nobenega\"",
    showRefuseItem: "Omogoči možnost \"Zavrni odgovor\"",
    showDontKnowItem: "Omogoči možnost \"Ne vem\"",
    noneText: "Preimenuj možnost \"Nobenega\"",
    showSelectAllItem: "Omogoči možnost \"Izberi vse\"",
    selectAllText: "Preimenuj možnost \"Izberi vse\"",
    choicesMin: "Minimalna vrednost za samodejno ustvarjene možnosti",
    choicesMax: "Maksimalna vrednost za samodejno ustvarjene možnosti",
    choicesStep: "Korak za samodejno ustvarjene možnosti",
    name: "Ime",
    title: "Naslov",
    cellType: "Vrsta vnosa v celici",
    colCount: "Število stolpcev",
    choicesOrder: "Vrsta izbire",
    visible: "Vidno",
    isRequired: "Obvezno",
    markRequired: "Označi kot obvezno",
    removeRequiredMark: "Odstrani oznako obveznosti",
    isAllRowRequired: "Zahtevajte odgovor v vsaki vrstici",
    eachRowUnique: "Prepreči podvojene odgovore v vrsticah",
    requiredErrorText: "Sporočilo o napaki za obvezna vprašanja",
    startWithNewLine: "Prikaz vprašanja na novi vrstici",
    rows: "Vrste",
    cols: "Stolpci",
    placeholder: "Besedilo vnosnega polja",
    showPreview: "Prikaz predogleda",
    storeDataAsText: "Shrani vsebino datoteke v JSON rezultat kot besedilo",
    maxSize: "Maksimalna velikost datoteke (v bajtih)",
    rowCount: "Število vrstic",
    columnLayout: "Postavitev stolpcev",
    addRowLocation: "Poravnava gumba »Dodaj vrstico«",
    transposeData: "Zamenjaj vrstice s stolpci",
    addRowText: "Besedilo gumba \"Dodaj vrstico\"",
    removeRowText: "Besedilo gumba \"Odstrani vrstico\"",
    rateMin: "Minimalna vrednost ocene",
    rateMax: "Maksimalna vrednost ocene",
    rateStep: "Korak vrednosti",
    minRateDescription: "Oznaka za minimalno vrednost",
    maxRateDescription: "Oznaka za maksimalno vrednost",
    inputType: "Vrsta vnosa",
    optionsCaption: "Zaslon možnosti",
    defaultValue: "Privzeti odgovor",
    cellsDefaultRow: "Privzeta besedila",
    surveyEditorTitle: "Uredi nastavitve ankete",
    qEditorTitle: "Uredi: {0}",
    maxLength: "Maksimalna dolžina znakov",
    buildExpression: "Ustvari",
    editExpression: "Uredi",
    and: "in",
    or: "ali",
    remove: "Odstrani",
    addCondition: "Dodaj pogoj",
    emptyLogicPopupMessage: "Izberite vprašanje, da začnete konfigurirati pogoje.",
    if: "Če",
    then: "potem",
    setToName: "Ciljno vprašanje",
    fromName: "Vprašanje za kopiranje odgovora",
    gotoName: "Vprašanje za preskočiti",
    ruleIsNotSet: "Pravilnik je napačen",
    includeIntoResult: "Dodaj v rezultate ankete",
    showTitle: "Pokaži naslov in opis",
    expandCollapseTitle: "Pokaži/skrivaj naslov",
    locale: "Izberite jezik ankete",
    simulator: "Izberite vrsto naprave",
    landscapeOrientation: "Preklopi v landscape usmeritev",
    portraitOrientation: "Preklopi v portrait usmeritev",
    mode: "Način prikaza ankete",
    clearInvisibleValues: "Počisti skrite vrednosti vprašanj",
    cookieName: "Omeji na en odgovor",
    sendResultOnPageNext: "Samodejno shranjevanje napredka ankete ob spremembi strani",
    storeOthersAsComment: "Shrani vrednost možnosti \"Drugo\" kot ločeno lastnost",
    showPageTitles: "Pokaži naslove strani",
    showPageNumbers: "Pokaži številke strani",
    pagePrevText: "Besedilo gumba \"Prejšnja stran\"",
    pageNextText: "Besedilo gumba \"Naslednja stran\"",
    completeText: "Besedilo gumba \"Zaključi anketo\"",
    previewText: "Besedilo gumba \"Pregled odgovorov\"",
    editText: "Besedilo gumba \"Uredi odgovor\"",
    startSurveyText: "Besedilo gumba \"Začni anketo\"",
    showNavigationButtons: "Pokaži gumbe za navigacijo",
    showPrevButton: "Pokaži gumb \"Prejšnja stran\"",
    firstPageIsStarted: "Prva stran je začetna stran",
    showCompletedPage: "Prikaz strani »Hvala«",
    goNextPageAutomatic: "Samodejno pomikanje na naslednjo stran",
    allowCompleteSurveyAutomatic: "Samodejno izpolnite anketo",
    showProgressBar: "Pokaži vrstico napredka",
    questionTitleLocation: "Poravnava naslova vprašanja",
    questionTitleWidth: "Širina naslova vprašanja",
    requiredText: "Zahtevani simbol(i)",
    questionTitleTemplate: "Predloga naslova vprašanja, privzeta vrednost: '{no}. {require} {title}'",
    questionErrorLocation: "Poravnava sporočila o napaki",
    focusFirstQuestionAutomatic: "Osredotočite se na prvo vprašanje na novi strani",
    questionsOrder: "Vrstni red vprašanj",
    timeLimit: "Časovna omejitev za izpolnitev ankete",
    timeLimitPerPage: "Časovna omejitev za eno stran",
    showTimer: "Uporabi timer",
    timerLocation: "Poravnava timerja",
    timerInfoMode: "Način timerja",
    renderMode: "Način prikaza panela",
    allowAddPanel: "Omogoči dodajanje panela",
    allowRemovePanel: "Omogoči odstranjevanje panela",
    panelAddText: "Besedilo gumba »Dodaj ploščo«",
    panelRemoveText: "Besedilo gumba »Odstrani ploščo«",
    isSinglePage: "Pokaži vse elemente na eni strani",
    html: "HTML oznaka",
    setValue: "Odgovor",
    dataFormat: "Oblikovanje shranjevanja",
    allowAddRows: "Omogoči dodajanje vrstic",
    allowRemoveRows: "Omogoči odstranjevanje vrstic",
    allowRowsDragAndDrop: "Omogočanje spreminjanja vrstic",
    responsiveImageSizeHelp: "To ne velja, če določite točno širino ali višino prikaznega območja.",
    minImageWidth: "Minimalna širina prikaznega območja",
    maxImageWidth: "Maksimalna širina prikaznega območja",
    minImageHeight: "Minimalna višina prikaznega območja",
    maxImageHeight: "Maksimalna višina prikaznega območja",
    minValue: "Minimalna vrednost",
    maxValue: "Maksimalna vrednost",
    caseInsensitive: "Brez upoštevanja velikosti črk",
    minLength: "Minimalna dolžina (v znakih)",
    allowDigits: "Omogoči številke",
    minCount: "Minimalno število",
    maxCount: "Maksimalno število",
    regex: "Regularni izraz",
    surveyvalidator: {
      text: "Sporočilo o napaki",
      expression: "Izraz za preverjanje"
    },
    totalText: "Povzetek vrstice",
    totalType: "Metoda agregacije",
    totalExpression: "Izraz za skupno vrednost",
    totalDisplayStyle: "Format prikaza skupne vrednosti",
    totalCurrency: "Valuta",
    totalFormat: "Formatiran niz",
    logo: "Logotip ankete",
    questionsOnPageMode: "Postavitev ankete",
    maxTextLength: "Omejitev dolžine odgovora",
    maxOthersLength: "Omejitev dolžine komentarja",
    commentAreaRows: "Višina območja za komentarje (v vrsticah)",
    autoGrowComment: "Samodejno širjenje besedilnih področij",
    allowResizeComment: "Omogoči spremembo velikosti besedilnih področij",
    textUpdateMode: "Posodobi vrednosti v vhodnih poljih",
    maskType: "Vrsta maske za vnos",
    focusOnFirstError: "Nastavitev fokusa na prvi neveljaven odgovor",
    checkErrorsMode: "Izvedi preverjanje",
    validateVisitedEmptyFields: "Preveri prazna polja pri izgubi fokusa",
    navigateToUrl: "Preusmeri na zunanjo povezavo po oddaji",
    navigateToUrlOnCondition: "Dinamična zunanja povezava",
    completedBeforeHtml: "Označevanje za prikaz, če je uporabnik že izpolnil to anketo",
    completedHtml: "Označevanje za stran »Hvala«",
    completedHtmlOnCondition: "Dinamično označevanje strani »Hvala«",
    loadingHtml: "Označevanje za prikaz med nalaganjem modela ankete",
    commentText: "Besedilo območja za komentarje",
    autocomplete: "Vrsta samodejnega dopolnjevanja",
    labelTrue: "Oznaka za »Resnično«",
    labelFalse: "Oznaka za »Neresnično«",
    allowClear: "Prikaži gumb za brisanje",
    searchMode: "Način iskanja",
    displayStyle: "Format prikaza",
    format: "Formatiran niz",
    maximumFractionDigits: "Največje število decimalnih mest",
    minimumFractionDigits: "Najmanjše število decimalnih mest",
    useGrouping: "Prikaži ločevalce za skupine",
    allowMultiple: "Omogoči večkratno nalaganje datotek",
    allowImagesPreview: "Ogled prenesenih slik",
    acceptedTypes: "Dovoljeni tipi datotek",
    waitForUpload: "Počakaj, da se nalaganje zaključi",
    needConfirmRemoveFile: "Potrdi brisanje datoteke",
    detailPanelMode: "Poravnava širjenja vrstice",
    minRowCount: "Najmanjše število vrstic",
    maxRowCount: "Največje število vrstic",
    confirmDelete: "Potrdi brisanje vrstice",
    confirmDeleteText: "Sporočilo za potrditev",
    panelCount: "Začetno število panelov",
    minPanelCount: "Najmanjše število panelov",
    maxPanelCount: "Največje število panelov",
    panelsState: "Stanje znotraj zapiranja panela",
    panelPrevText: "Besedilo gumba »Prejšnja plošča«",
    panelNextText: "Besedilo gumba »Naslednja plošča«",
    panelRemoveButtonLocation: "Poravnava gumba »Odstrani ploščo«",
    hideIfRowsEmpty: "Skrij vprašanje, če nima vrstic",
    hideColumnsIfEmpty: "Skrij stolpce, če ni vrstic",
    rateValues: "Prilagojene vrednosti ocene",
    rateCount: "Število ocen",
    autoGenerate: "Konfiguracija ocene",
    hideIfChoicesEmpty: "Skrij vprašanje, če nima možnosti",
    minWidth: "Najmanjša širina",
    maxWidth: "Največja širina",
    width: "Širina",
    showHeader: "Prikaži glave stolpcev",
    horizontalScroll: "Prikaži horizontalni drsnik",
    columnMinWidth: "Najmanjša širina stolpca",
    rowTitleWidth: "Širina glave vrstice",
    valueTrue: "Vrednost, ki jo shranimo, ko je izbrano »Resnično«",
    valueFalse: "Vrednost, ki jo shranimo, ko je izbrano »Neresnično«",
    minErrorText: "Sporočilo o napaki »Vrednost je pod minimalno vrednostjo«",
    maxErrorText: "Sporočilo o napaki »Vrednost presega največjo vrednost«",
    otherErrorText: "Sporočilo o napaki »Prazni komentar«",
    keyDuplicationError: "Sporočilo o napaki za podvojene odgovore",
    minSelectedChoices: "Minimalno število izbranih možnosti",
    maxSelectedChoices: "Največje število izbranih možnosti",
    showClearButton: "Pokaži gumb »Počisti«",
    logoWidth: "Širina logotipa",
    logoHeight: "Višina logotipa",
    readOnly: "Samo za branje",
    enableIf: "Onemogoči način samo za branje, če",
    emptyRowsText: "Sporočilo »Brez vrstic«",
    separateSpecialChoices: "Ločene posebne možnosti",
    choicesFromQuestion: "Kopiraj možnosti iz naslednjega vprašanja",
    choicesFromQuestionMode: "Katero izbiro možnosti kopirati",
    progressBarShowPageTitles: "Prikaz naslovov strani v napredku",
    progressBarShowPageNumbers: "Prikaz številk strani v napredku",
    showCommentArea: "Dodaj polje za komentarje",
    commentPlaceholder: "Besedilo za polje s komentarji",
    displayRateDescriptionsAsExtremeItems: "Prikaz oznak kot ekstremnih vrednosti",
    rowsOrder: "Vrstni red vrstic",
    columnsLayout: "Postavitev stolpcev",
    columnColCount: "Število gnezdenih stolpcev",
    correctAnswer: "Pravilni odgovor",
    defaultPanelValue: "Privzete vrednosti",
    cells: "Besedila celic",
    fileInputPlaceholder: "Izberite datoteko ali prilepite povezavo do datoteke...",
    keyName: "Prepreči podvojene odgovore v naslednjem stolpcu",
    itemvalue: {
      visibleIf: "Naredi možnost vidno, če",
      enableIf: "Naredi možnost izbirno, če"
    },
    "itemvalue@rows": {
      visibleIf: "Naredi vrstico vidno, če",
      enableIf: "Naredi vrstico urejljivo, če"
    },
    imageitemvalue: {
      text: "Alt besedilo"
    },
    logoPosition: "Poravnava logotipa",
    addLogo: "Dodaj logotip...",
    changeLogo: "Spremeni logotip...",
    logoPositions: {
      none: "Odstrani logotip",
      left: "Levo",
      right: "Desno",
      top: "Na vrhu",
      bottom: "Na dnu"
    },
    gridLayoutEnabled: "Omogoči mrežno postavitev",
    gridLayoutColumns: "Mrežne stolpce",
    maskSettings: "Nastavitve maske",
    detailErrorLocation: "Poravnava sporočila o napaki pri razširitvi vrstice",
    // Creator tabs
    tabs: {
      panel: {
        layout: "Postavitev panela"
      },
      general: "Splošno",
      fileOptions: "Možnosti",
      html: "HTML Urejevalnik",
      columns: "Stolpci",
      rows: "Vrstice",
      choices: "Možnosti izbire",
      items: "Postavke",
      visibleIf: "Vidno, če",
      enableIf: "Omogočeno, če",
      requiredIf: "Zahtevano, če",
      rateValues: "Vrednosti ocene",
      choicesByUrl: "Možnosti iz spletne storitve",
      matrixChoices: "Privzete možnosti",
      multipleTextItems: "Vnos besedila",
      numbering: "Številčenje",
      validators: "Preverjevalniki",
      navigation: "Navigacija",
      question: "Nastavitve vprašanja",
      pages: "Strani",
      timer: "Način kviza",
      calculatedValues: "Izračunane vrednosti",
      triggers: "Sprožilci",
      templateTitle: "Predloga naslova",
      totals: "Skupaj",
      logic: "Pogoji",
      mask: "Nastavitve maske za vnos",
      layout: {
        panel: "Postavitev panela",
        question: "Postavitev vprašanja",
        base: "Osnovna postavitev"
      },
      data: "Podatki",
      validation: "Preverjanje",
      cells: "Besedila posameznih celic",
      showOnCompleted: "Stran 'Hvala' (Po zaključku)",
      logo: "Logo v glavi ankete",
      slider: "Drsnik",
      expression: "Izraz",
      questionSettings: "Nastavitve vprašanja",
      header: "Glava",
      background: "Ozadetje",
      appearance: "Videzo",
      accentColors: "Poudarjene barve",
      surfaceBackground: "Ozadetje površine",
      scaling: "Spreminjanje velikosti",
      others: "Drugi"
    },
    editProperty: "Uredi lastnost '{0}'",
    items: "Postavke",
    choicesVisibleIf: "Pokaži možnosti, če",
    choicesEnableIf: "Omogoči možnosti, če",
    columnsEnableIf: "Pokaži stolpce, če",
    rowsEnableIf: "Pokaži vrstice, če",
    innerIndent: "Povečaj notranji zamik",
    defaultValueFromLastRow: "Privzeto uporabi odgovore iz zadnje vrstice",
    defaultValueFromLastPanel: "Privzeto uporabi odgovore iz zadnje plošče",
    enterNewValue: "Prosimo, vnesite novo vrednost.",
    noquestions: "V anketi ni vprašanj.",
    createtrigger: "Prosimo, ustvarite sprožilec",
    titleKeyboardAdornerTip: "Pritisnite tipko enter za urejanje",
    keyboardAdornerTip: "Pritisnite tipko enter za urejanje postavke, pritisnite tipko delete za izbris postavke, pritisnite alt skupaj z zgornjo ali spodnjo puščico za premik postavke",
    triggerOn: "Na",
    triggerMakePagesVisible: "Pokaži strani",
    triggerMakeQuestionsVisible: "Pokaži elemente",
    triggerCompleteText: "Dokončaj anketo, če je uspešno.",
    triggerNotSet: "Sprožilec ni nastavljen",
    triggerRunIf: "Zaženi, če",
    triggerSetToName: "Spremeni vrednost za: ",
    triggerFromName: "Kopiraj vrednost iz: ",
    triggerRunExpression: "Zaženi ta izraz",
    triggerSetValue: "na: ",
    triggerGotoName: "Pojdi na vprašanje",
    triggerIsVariable: "Ne vključuj spremenljivke v rezultate ankete.",
    triggerRunExpressionEmpty: "Prosimo, vnesite veljaven izraz",
    emptyExpressionPlaceHolder: "Vnesite izraz tukaj...",
    noFile: "Datoteka ni izbrana",
    clearIfInvisible: "Počisti skrite vrednosti vprašanj",
    valuePropertyName: "Shrani vrednosti v naslednjo lastnost",
    searchEnabled: "Omogoči iskanje med tipkanjem",
    hideSelectedItems: "Skrij izbrane postavke",
    closeOnSelect: "Zaprti spustni seznam ob izbiri",
    verticalAlign: "Verticalno poravnavanje v celicah",
    alternateRows: "Izmenični barvni odtenki vrstic",
    columnsVisibleIf: "Pokaži stolpce, če",
    rowsVisibleIf: "Pokaži vrstice, če",
    otherPlaceholder: "Besedilo za nadomestno polje za komentarje",
    filePlaceholder: "Besedilo za nadomestno polje za lokalno datoteko",
    photoPlaceholder: "Besedilo za nadomestno polje za kamero",
    fileOrPhotoPlaceholder: "Besedilo za nadomestno polje za lokalno datoteko ali kamero",
    rateType: "Vrsta ikone za ocenjevanje",
    url_placeholder: "Npr.: https://api.example.com/books",
    path_placeholder: "Npr.: categories.fiction",
    questionStartIndex_placeholder: "Npr.: a)",
    width_placeholder: "Npr.: 6in",
    minWidth_placeholder: "Npr.: 600px",
    maxWidth_placeholder: "Npr.: 50%",
    imageHeight_placeholder: "avtomatsko",
    imageWidth_placeholder: "avtomatsko",
    theme: {
      themeName: "Tema",
      isPanelless: "Pogled vprašanja",
      editorPanel: "Ozadje in polmer robov",
      questionPanel: "Ozadje in polmer robov",
      primaryColor: "Poudarni barvni odtenek",
      panelBackgroundTransparency: "Prosojnost panela in okvirja vprašanja",
      questionBackgroundTransparency: "Prosojnost vhodnega elementa",
      fontSize: "Velikost pisave ankete",
      scale: "Delež lestvice ankete",
      cornerRadius: "Polmer robov",
      advancedMode: "Napreden način",
      pageTitle: "Pisava naslova strani",
      pageDescription: "Pisava opisa strani",
      questionTitle: "Pisava naslova vprašanja",
      questionDescription: "Pisava opisa vprašanja",
      editorFont: "Pisava",
      backgroundOpacity: "Prosojnost",
      "--sjs-font-family": "Pisava ankete",
      "--sjs-general-backcolor-dim": "Barva ozadja",
      "--sjs-primary-backcolor": "Poudarne barve ozadja",
      "--sjs-primary-forecolor": "Poudarne barve besedila",
      "--sjs-special-red": "Barve sporočil o napakah",
      "--sjs-shadow-small": "Efekti senc",
      "--sjs-shadow-inner": "Efekti notranjih senc",
      "--sjs-border-default": "Barve"
    },
    "header@header": {
      headerView: "Pogled",
      logoPosition: "Poravnava logotipa",
      surveyTitle: "Pisava naslova ankete",
      surveyDescription: "Pisava opisa ankete",
      headerTitle: "Pisava naslova glave",
      headerDescription: "Pisava opisa glave",
      inheritWidthFrom: "Širina vsebinskega področja",
      textAreaWidth: "Širina besedila",
      backgroundColorSwitch: "Barva ozadja",
      backgroundImage: "Ozadje slike",
      backgroundImageOpacity: "Prosojnost ozadja slike",
      overlapEnabled: "Prekrivanje omogočeno",
      logoPositionX: "Poravnava logotipa",
      titlePositionX: "Poravnava naslova ankete",
      descriptionPositionX: "Poravnava opisa ankete"
    }
  },
  // Property values
  pv: {
    "true": "resnično",
    "false": "napačno",
    file: "Lokalna datoteka",
    camera: "Kamera",
    "file-camera": "Lokalna datoteka ali Kamera",
    inherit: "Dedovanje",
    show: "Vidno",
    hide: "Skrito",
    default: "Privzeto",
    initial: "Začetno",
    random: "Naključno",
    collapsed: "Zmanjšano",
    expanded: "Razširjeno",
    none: "Brez",
    asc: "Naraščajoče",
    desc: "Padajoče",
    indeterminate: "Nedoločeno",
    selected: "Izbrano",
    unselected: "Neizbrano",
    decimal: "decimalno",
    currency: "valuta",
    percent: "odstotek",
    firstExpanded: "Prvi panel je razširjen",
    off: "Skrij številke vprašanj",
    list: "Seznam",
    carousel: "Krožni pregled",
    tab: "Zavihek",
    progressTop: "Navigacija panela + napredna vrstica na vrhu",
    progressBottom: "Navigacija panela + napredna vrstica na dnu",
    progressTopBottom: "Navigacija panela + napredna vrstica na vrhu in dnu",
    horizontal: "Horizontalno",
    vertical: "Vertikalno",
    top: "Zgoraj",
    bottom: "Spodaj",
    topBottom: "Zgoraj in spodaj",
    both: "Oboje",
    left: "Levo",
    right: "Desno",
    center: "Središče",
    leftRight: "Levo in desno",
    middle: "Sredina",
    color: "barva",
    date: "datum",
    datetime: "datum in čas",
    "datetime-local": "lokalni datum in čas",
    email: "e-pošta",
    month: "mesec",
    number: "številka",
    password: "geslo",
    range: "obseg",
    tel: "telefon",
    text: "besedilo",
    time: "čas",
    url: "url",
    week: "teden",
    hidden: "Skrito",
    edit: "Za urejanje",
    display: "Samo za branje",
    contain: "Vsebuje",
    cover: "Prekriva",
    fill: "Izpolni",
    next: "Naprej",
    last: "Zadnji",
    onComplete: "Ob zaključku ankete",
    onHidden: "Ko je vprašanje skrito",
    onHiddenContainer: "Ko je vprašanje ali njegov panel/stran skrita",
    clearInvisibleValues: {
      none: "Nikoli"
    },
    clearIfInvisible: {
      none: "Nikoli"
    },
    inputType: {
      color: "Barva",
      date: "Datum",
      "datetime-local": "Datum a čas",
      email: "E-mail",
      month: "Měsíc",
      number: "Číslo",
      password: "Heslo",
      range: "Rozsah",
      tel: "Telefonní číslo",
      text: "Text",
      time: "Čas",
      url: "URL",
      week: "Týden"
    },
    autocomplete: {
      name: "Celé jméno",
      "honorific-prefix": "Prefix",
      "given-name": "Křestní jméno",
      "additional-name": "Střední jméno",
      "family-name": "Příjmení",
      "honorific-suffix": "Přípona",
      nickname: "Přezdívka",
      "organization-title": "Pozice",
      username: "Uživatelské jméno",
      "new-password": "Nové heslo",
      "current-password": "Aktuální heslo",
      organization: "Název organizace",
      "street-address": "Celá adresa",
      "address-line1": "Adresa řádek 1",
      "address-line2": "Adresa řádek 2",
      "address-line3": "Adresa řádek 3",
      "address-level4": "Úroveň 4 adresa",
      "address-level3": "Úroveň 3 adresa",
      "address-level2": "Úroveň 2 adresa",
      "address-level1": "Úroveň 1 adresa",
      country: "Kód země",
      "country-name": "Název země",
      "postal-code": "PSČ",
      "cc-name": "Jméno držitele karty",
      "cc-given-name": "Křestní jméno držitele karty",
      "cc-additional-name": "Střední jméno držitele karty",
      "cc-family-name": "Příjmení držitele karty",
      "cc-number": "Číslo kreditní karty",
      "cc-exp": "Datum expirace",
      "cc-exp-month": "Měsíc expirace",
      "cc-exp-year": "Rok expirace",
      "cc-csc": "Kód bezpečnosti karty",
      "cc-type": "Typ kreditní karty",
      "transaction-currency": "Měna transakce",
      "transaction-amount": "Částka transakce",
      language: "Preferovaný jazyk",
      bday: "Datum narození",
      "bday-day": "Den narození",
      "bday-month": "Měsíc narození",
      "bday-year": "Rok narození",
      sex: "Pohlaví",
      url: "URL webové stránky",
      photo: "Profilová fotografie",
      tel: "Telefonní číslo",
      "tel-country-code": "Kód země pro telefon",
      "tel-national": "Národní telefonní číslo",
      "tel-area-code": "Předvolba oblasti",
      "tel-local": "Místní telefonní číslo",
      "tel-local-prefix": "Místní telefonní předvolba",
      "tel-local-suffix": "Místní telefonní přípona",
      "tel-extension": "Telefonní linka",
      email: "Emailová adresa",
      impp: "Protokol okamžitého zasílání zpráv"
    },
    maskType: {
      none: "Žádný",
      pattern: "Vzor",
      numeric: "Číselný",
      datetime: "Datum a čas",
      currency: "Měna"
    },
    inputTextAlignment: {
      auto: "Automaticky",
      left: "Vlevo",
      right: "Vpravo"
    },
    all: "Vše",
    page: "Stránka",
    survey: "Průzkum",
    onNextPage: "Při přechodu na další stránku",
    onValueChanged: "Po změně odpovědi",
    onValueChanging: "Před změnou odpovědi",
    questionsOnPageMode: {
      standard: "Původní struktura",
      singlePage: "Zobrazit všechny otázky na jedné stránce",
      questionPerPage: "Zobrazit jednu otázku na stránce"
    },
    noPreview: "Žádný náhled",
    showAllQuestions: "Zobrazit všechny otázky",
    showAnsweredQuestions: "Zobrazit pouze zodpovězené otázky",
    pages: "Dokončené stránky",
    questions: "Zodpovězené otázky",
    requiredQuestions: "Zodpovězené povinné otázky",
    correctQuestions: "Platné odpovědi",
    buttons: "Dokončené stránky (tlačítkový UI)",
    underInput: "Pod vstupním polem",
    underTitle: "Pod názvem otázky",
    onBlur: "Při ztrátě zaměření",
    onTyping: "Během psaní",
    underRow: "Pod řádkem",
    underRowSingle: "Pod řádkem, zobrazit pouze jednu expanze řádku",
    auto: "Automaticky",
    showNavigationButtons: {
      none: "Skryté"
    },
    timerInfoMode: {
      combined: "Obojí"
    },
    addRowLocation: {
      default: "Na podlagi postavitve matrike"
    },
    panelsState: {
      default: "Zamčeno",
      collapsed: "Sbalit vše",
      expanded: "Rozbalit vše",
      firstExpanded: "První rozbalený"
    },
    widthMode: {
      static: "Statické",
      responsive: "Responzivní"
    },
    contentMode: {
      image: "Obrázek",
      video: "Video",
      youtube: "YouTube"
    },
    displayMode: {
      buttons: "Tlačítka",
      dropdown: "Rozbalovací nabídka"
    },
    rateColorMode: {
      default: "Výchozí",
      scale: "Měřítko"
    },
    scaleColorMode: {
      monochrome: "Monochromatické",
      colored: "Barevné"
    },
    autoGenerate: {
      "true": "Automatické generování",
      "false": "Manuální"
    },
    rateType: {
      labels: "Štítky",
      stars: "Hvězdičky",
      smileys: "Smajlíky"
    },
    state: {
      default: "Zamčeno"
    },
    showQuestionNumbers: {
      default: "Automatsko numerisanje",
      on: "Automatsko numerisanje",
      onPage: "Resetuj na svakoj stranici",
      onpanel: "Resetuj na svakom panelu",
      onPanel: "Resetuj na svakom panelu",
      onSurvey: "Nastavi kroz anketu",
      off: "Bez numerisanja"
    },
    descriptionLocation: {
      underTitle: "Ispod naslova pitanja",
      underInput: "Ispod unosa"
    },
    selectToRankAreasLayout: {
      horizontal: "Pored opcija",
      vertical: "Iznad opcija"
    },
    displayStyle: {
      decimal: "Decimal",
      currency: "Valuta",
      percent: "Procenat",
      date: "Datum"
    },
    totalDisplayStyle: {
      decimal: "Decimal",
      currency: "Valuta",
      percent: "Procenat",
      date: "Datum"
    },
    rowsOrder: {
      initial: "Izvirno"
    },
    questionsOrder: {
      initial: "Izvirno"
    },
    showProgressBar: {
      off: "Skriven",
      topbottom: "Zgornji in spodnji del",
      aboveheader: "Nad glavo",
      belowheader: "Pod glavo"
    },
    progressBarLocation: {
      top: "Zgoraj",
      bottom: "Spodaj",
      topBottom: "Zgornji in spodnji del"
    },
    sum: "Vsota",
    count: "Štetje",
    min: "Min",
    max: "Max",
    avg: "Povprečje",
    searchMode: {
      contains: "Vsebuje",
      startsWith: "Se začne z"
    },
    backgroundImageFit: {
      auto: "Samodejno",
      cover: "Pokrij",
      contain: "Vsebuje",
      fill: "Raztegniti",
      tile: "Ploščice"
    },
    backgroundImageAttachment: {
      fixed: "Fiksno",
      scroll: "Pomikanje"
    },
    headerView: {
      basic: "Osnovno",
      advanced: "Napredno"
    },
    inheritWidthFrom: {
      survey: "Enako kot raziskava",
      container: "Enako kot vsebnik"
    },
    backgroundColorSwitch: {
      none: "Nič",
      accentColor: "Poudarna barva",
      custom: "Po meri"
    },
    colorPalette: {
      light: "Svetla",
      dark: "Temna"
    },
    isPanelless: {
      "false": "Privzeto",
      "true": "Brez panelov"
    },
    progressBarInheritWidthFrom: {
      survey: "Enako kot anketa",
      container: "Enako kot vsebnik"
    }
  },
  // Operators
  op: {
    empty: "Prazno",
    notempty: "Ni prazno",
    equal: "Enako",
    notequal: "Ni enako",
    contains: "Vsebuje",
    notcontains: "Ne vsebuje",
    anyof: "Karkoli od",
    allof: "Vse od",
    greater: "Večje kot",
    less: "Manjše kot",
    greaterorequal: "Večje ali enako",
    lessorequal: "Manjše ali enako",
    and: "in",
    or: "ali"
  },
  // Embed window
  ew: {
    angular: "Uporabi različico Angular",
    jquery: "Uporabi različico jQuery",
    knockout: "Uporabi različico Knockout",
    react: "Uporabi različico React",
    vue: "Uporabi različico Vue",
    bootstrap: "Za okvir Bootstrap",
    modern: "Sodobna tema",
    default: "Privzeta tema",
    orange: "Oranžna tema",
    darkblue: "Temno modra tema",
    darkrose: "Temno roza tema",
    stone: "Kamnita tema",
    winter: "Zimska tema",
    winterstone: "Zimsko-kamnita tema",
    showOnPage: "Prikaži anketo na strani",
    showInWindow: "Prikaži anketo v oknu",
    loadFromServer: "Naloži Survey JSON s strežnika",
    titleScript: "Skripte in slogi",
    titleHtml: "HTML",
    titleJavaScript: "JavaScript"
  },
  // Preview (Survey)
  ts: {
    selectPage: "Izberi stran za testiranje",
    showInvisibleElements: "Prikaži nevidne elemente",
    hideInvisibleElements: "Skrij nevidne elemente"
  },
  validators: {
    answercountvalidator: "Število odgovorov",
    emailvalidator: "E-pošta",
    expressionvalidator: "Izraz",
    numericvalidator: "Število",
    regexvalidator: "Regex",
    textvalidator: "Besedilo"
  },
  triggers: {
    completetrigger: "Zaključi anketu",
    setvaluetrigger: "Nastavi odgovor",
    copyvaluetrigger: "Kopiraj odgovor",
    skiptrigger: "Preskoči na vprašanje",
    runexpressiontrigger: "Zaženi izraz",
    visibletrigger: "Spremeni vidnost (zastarelo)"
  },
  peplaceholder: {
    patternmask: {
      pattern: "Npr.: +1(999)-999-99-99"
    },
    datetimemask: {
      pattern: "Npr.: mm/dd/yyyy HH:MM:ss"
    },
    currencymask: {
      prefix: "Npr.: $",
      suffix: "Npr.: USD"
    },
    panelbase: {
      questionTitleWidth: "Npr.: 200px"
    }
  },
  pehelp: {
    panel: {
      name: "ID panela, ki ni viden anketirancem.",
      description: "Vnesite podnaslov panela.",
      visibleIf: "Uporabite ikono čarobne paličice za nastavitev pogojnega pravila, ki določa vidnost panela.",
      enableIf: "Uporabite ikono čarobne paličice za nastavitev pogojnega pravila, ki onemogoči način samo za branje za panel.",
      requiredIf: "Uporabite ikono čarobne paličice za nastavitev pogojnega pravila, ki prepreči oddajo ankete, razen če je vsaj eno vprašanje znotraj panela odgovorjeno.",
      questionTitleLocation: "Velja za vsa vprašanja znotraj tega panela. Ko je nastavljeno na \"Skrito\", skrije tudi opise vprašanj. Če želite prekiniti to nastavitev, določite pravila za poravnavo naslovov za posamezna vprašanja. Možnost \"Dedovanje\" uporabi nastavitev na ravni strani (če je nastavljeno) ali na ravni ankete (\"Na vrhu\" privzeto).",
      questionTitleWidth: "Določi enotno širino za naslove vprašanj, ko so poravnani levo od svojih polj za vprašanja. Sprejema CSS vrednosti (px, %, in, pt, itd.).",
      questionErrorLocation: "Določi lokacijo sporočila o napaki v zvezi z vsemi vprašanji znotraj panela. Možnost \"Dedovanje\" uporabi nastavitev na ravni strani (če je nastavljeno) ali na ravni ankete.",
      questionsOrder: "Ohrani prvotni vrstni red vprašanj ali jih naključno razvrsti. Možnost »Podeduj« uporablja nastavitev na ravni strani (če je nastavljena) ali na ravni ankete.",
      page: "Premakne panel na konec izbrane strani.",
      innerIndent: "Doda prostor ali rob med vsebino panela in levo mejo panela.",
      startWithNewLine: "Odznači, da bo panel prikazan v eni vrstici s prejšnjim vprašanjem ali panelom. Nastavitev ne velja, če je panel prvi element v obrazcu.",
      state: "Izberite iz: \"Razširjeno\" - panel je prikazan v celoti in ga je mogoče zložiti; \"Zloženo\" - panel prikazuje samo naslov in opis ter ga je mogoče razširiti; \"Zaklenjeno\" - panel je prikazan v celoti in ga ni mogoče zložiti.",
      width: "Določi širino panela v razmerju z drugimi elementi ankete v isti vrstici. Sprejema CSS vrednosti (px, %, in, pt, itd.).",
      showQuestionNumbers: "Dodeli številke vprašanjem, ki so znotraj tega panela."
    },
    paneldynamic: {
      name: "ID panela, ki ni viden anketirancem.",
      description: "Vnesite podnaslov panela.",
      visibleIf: "Uporabite ikono čarobne paličice za nastavitev pogojnega pravila, ki določa vidnost panela.",
      enableIf: "Uporabite ikono čarobne paličice za nastavitev pogojnega pravila, ki onemogoči način samo za branje za panel.",
      requiredIf: "Uporabite ikono čarobne paličice za nastavitev pogojnega pravila, ki prepreči oddajo ankete, razen če je vsaj eno vprašanje znotraj panela odgovorjeno.",
      templateTitleLocation: "Velja za vsa vprašanja v tej plošči. Če želite preglasiti to nastavitev, določite pravila za poravnavo naslovov za posamezna vprašanja. Možnost »Podeduj« uporablja nastavitev na ravni strani (če je nastavljena) ali na ravni ankete (privzeto »na vrh«).",
      templateErrorLocation: "Določi lokacijo sporočila o napaki v zvezi z vprašanjem z neveljavnim vnosom. Izberite med: \"Na vrhu\" - sporočilo o napaki je postavljeno na vrh okvira vprašanja; \"Na dnu\" - sporočilo o napaki je postavljeno na dno okvira vprašanja. Možnost \"Dedovanje\" uporabi nastavitev na ravni strani (če je nastavljeno) ali na ravni ankete.",
      errorLocation: "Določi lokacijo sporočila o napaki v zvezi z vsemi vprašanji znotraj panela. Možnost \"Dedovanje\" uporabi nastavitev na ravni strani (če je nastavljeno) ali na ravni ankete.",
      page: "Premakne panel na konec izbrane strani.",
      innerIndent: "Doda prostor ali rob med vsebino panela in levo mejo panela.",
      startWithNewLine: "Odznači, da bo panel prikazan v eni vrstici s prejšnjim vprašanjem ali panelom. Nastavitev ne velja, če je panel prvi element v obrazcu.",
      state: "Izberite iz: \"Razširjeno\" - panel je prikazan v celoti in ga je mogoče zložiti; \"Zloženo\" - panel prikazuje samo naslov in opis ter ga je mogoče razširiti; \"Zaklenjeno\" - panel je prikazan v celoti in ga ni mogoče zložiti.",
      width: "Določi širino panela v razmerju z drugimi elementi ankete v isti vrstici. Sprejema CSS vrednosti (px, %, in, pt, itd.).",
      templateTitle: "Vnesite predlogo za dinamične naslove panelov. Uporabite {panelIndex} za splošno pozicijo panela in {visiblePanelIndex} za njegov vrstni red med vidnimi paneli. Vstavite te označevalce v vzorec za dodajanje samodejnega številčenja.",
      templateTabTitle: "Vnesite predlogo za naslove zavihkov. Uporabite {panelIndex} za splošno pozicijo panela in {visiblePanelIndex} za njegov vrstni red med vidnimi paneli. Vstavite te označevalce v vzorec za dodajanje samodejnega številčenja.",
      tabTitlePlaceholder: "Zasilni tekst za naslove zavihkov, ki se uporabi, kadar predloga za naslov zavihka ne ustvari smiselne vrednosti.",
      templateVisibleIf: "Ta nastavitev vam omogoča nadzor nad vidnostjo posameznih panelov znotraj dinamičnega panela. Uporabite označevalec `{panel}` za sklicevanje na trenutni panel v vaši izrazi.",
      titleLocation: "Ta nastavitev se samodejno deduje za vsa vprašanja znotraj tega panela. Če želite prekiniti to nastavitev, določite pravila za poravnavo naslovov za posamezna vprašanja. Možnost \"Dedovanje\" uporabi nastavitev na ravni strani (če je nastavljeno) ali na ravni ankete (\"Na vrhu\" privzeto).",
      descriptionLocation: "Možnost \"Dedovanje\" uporabi nastavitev na ravni strani (če je nastavljeno) ali na ravni ankete (\"Pod naslovom panela\" privzeto).",
      newPanelPosition: "Določi pozicijo novega panela. Privzeto se novi paneli dodajo na konec. Izberite \"Naslednji\", da vstavite nov panel po trenutnem.",
      defaultValueFromLastPanel: "Podvoji odgovore iz zadnje plošče in jih dodeli naslednji dodani dinamični plošči.",
      keyName: "Sklicujte se na ime vprašanja, da zahteva od uporabnika, da za to vprašanje v vsakem panelu poda edinstven odgovor."
    },
    defaultValueExpression: "Ta nastavitev vam omogoča dodelitev privzetega odgovora na podlagi izraza. Izraz lahko vključuje osnovne izračune - `{q1_id} + {q2_id}`, Booleove izraze, kot je `{age} > 60`, in funkcije: `iif()`, `today()`, `age()`, `min()`, `max()`, `avg()`, itd. Vrednost, določena z tem izrazom, služi kot začetna privzeta vrednost, ki jo lahko respondent prekine z ročnim vnosom.",
    resetValueIf: "Uporabite ikono čarobne paličice za nastavitev pogojnega pravila, ki določa, kdaj naj se vnos respondenta ponastavi na vrednost, določeno z \"Izraz privzete vrednosti\" ali \"Izraz nastavitev vrednosti\" ali na \"Privzeti odgovor\" (če je eden od njih nastavljen).",
    setValueIf: "Uporabite ikono čarobne paličice za nastavitev pogojnega pravila, ki določa, kdaj izvesti \"Izraz nastavitev vrednosti\" in dinamično dodeliti rezultat kot odgovor.",
    setValueExpression: "Določite izraz, ki opredeli vrednost, ki se nastavi, ko so izpolnjeni pogoji v pravilu \"Nastavi vrednost, če\". Izraz lahko vključuje osnovne izračune - `{q1_id} + {q2_id}`, Booleove izraze, kot je `{age} > 60`, in funkcije: `iif()`, `today()`, `age()`, `min()`, `max()`, `avg()`, itd. Vrednost, določena z tem izrazom, lahko prekine respondentov ročni vnos.",
    question: {
      name: "ID vprašanja, ki ni viden respondentu.",
      description: "Vnesite podnaslov vprašanja.",
      visibleIf: "Uporabite ikono čarobne paličice za nastavitev pogojnega pravila, ki določa vidnost vprašanja.",
      enableIf: "Uporabite ikono čarobne paličice za nastavitev pogojnega pravila, ki onemogoči način samo za branje za vprašanje.",
      requiredIf: "Uporabite ikono čarobne paličice za nastavitev pogojnega pravila, ki prepreči napredovanje ankete ali oddajo, razen če vprašanje prejme odgovor.",
      startWithNewLine: "Odkljukajte, če želite vprašanje prikazati v eni vrstici s prejšnjim vprašanjem ali panelom. Nastavitev ne velja, če je vprašanje prvi element v obrazcu.",
      page: "Preuredi vprašanje na konec izbrane strani.",
      state: "Izberite med: \"Razširjeno\" - vprašanje je prikazano v celoti in ga je mogoče zložiti; \"Zloženo\" - vprašanje prikazuje samo naslov in opis ter ga je mogoče razširiti; \"Zaklenjeno\" - vprašanje je prikazano v celoti in ga ni mogoče zložiti.",
      titleLocation: "Prepiše pravila poravnave naslovov, določena na ravni panela, strani ali ankete. Ko je nastavljeno na \"Skrito\", se skrijejo tudi opisi vprašanj. Možnost \"Podedovano\" uporablja nastavitve višje ravni (če so nastavljene) ali nastavitve ankete (\"Zgoraj\" privzeto).",
      descriptionLocation: "Možnost \"Podedovano\" uporablja nastavitev na ravni ankete (\"Pod naslovom vprašanja\" privzeto).",
      errorLocation: "Nastavi lokacijo sporočila o napaki v povezavi z vprašanjem z neveljavnim vhodom. Izberite med: \"Zgoraj\" - sporočilo o napaki je postavljeno na vrh vprašalnika; \"Spodaj\" - sporočilo o napaki je postavljeno na dno vprašalnika. Možnost \"Podedovano\" uporablja nastavitev na ravni ankete (\"Zgoraj\" privzeto).",
      indent: "Doda prostor ali rob med vsebino vprašanja in levim robom vprašalnika.",
      width: "Nastavi širino vprašanja glede na druge elemente ankete v isti vrstici. Sprejema CSS vrednosti (px, %, in, pt, itd.).",
      surveyvalidator: {
        expression: "Uporabite ikono čarobne paličice za nastavitev pravila za preverjanje veljavnosti vprašanja."
      },
      textUpdateMode: "Izberite med: \"Ob izgubi fokusa\" - vrednost se posodobi, ko vnosno polje izgubi fokus; \"Med tipkanjem\" - vrednost se posodablja v realnem času med tipkanjem uporabnikov. Možnost \"Podedovano\" uporablja nastavitev na ravni ankete (privzeto \"Ob izgubi fokusa\").",
      url: "Kot vir podatkov za vprašanja z več možnimi odgovori lahko uporabite katero koli spletno storitev. Za izpolnitev vrednosti izbire vnesite URL storitve, ki zagotavlja podatke.",
      searchMode: "Primerjalna operacija, ki se uporablja za filtriranje spustnega seznama.",
      textWrapEnabled: "Dolga besedila v možnostih izbire bodo samodejno prelomljena, da se prilegajo v spustni meni. Odznačite, če želite, da se besedila odrežejo."
    },
    signaturepad: {
      signatureWidth: "Določa širino prikazanega območja podpisa in končne slike.",
      signatureHeight: "Določa višino prikazanega območja podpisa in končne slike.",
      signatureAutoScaleEnabled: "Izberite, če želite, da se območje podpisa zapolni z vsem razpoložljivim prostorom v polju vprašanja ob ohranjanju privzetega razmerja stranic 3:2. Ko so nastavljene prilagojene vrednosti širine in višine, bo nastavitev ohranila razmerje teh dimenzij."
    },
    file: {
      imageHeight: "Prilagodi višino slike v rezultatih ankete.",
      imageWidth: "Prilagodi širino slike v rezultatih ankete.",
      allowImagesPreview: "Prikaže sličice naloženih datotek, kadar je to mogoče. Odznačite, če želite namesto tega prikazati ikone datotek."
    },
    image: {
      contentMode: "Možnost \"Samodejno\" samodejno določi ustrezen način prikaza – slika, video ali YouTube – glede na podani vir URL."
    },
    imagepicker: {
      imageHeight: "Prepiše najmanjše in največje vrednosti višine.",
      imageWidth: "Prepiše najmanjše in največje vrednosti širine.",
      choices: "\"Vrednost\" služi kot ID elementa, ki se uporablja v pogojnih pravilih; \"Besedilo\" je prikazano anketirancem.",
      contentMode: "Izberite med \"Slika\" in \"Video\", da nastavite način vsebine izbirnika medijev. Če je izbrana možnost \"Slika\", poskrbite, da so vse ponujene možnosti slikovne datoteke v naslednjih formatih: JPEG, GIF, PNG, APNG, SVG, BMP, ICO. Podobno, če je izbrana možnost \"Video\", zagotovite, da so vse možnosti neposredne povezave do video datotek v naslednjih formatih: MP4, MOV, WMV, FLV, AVI, MKV. Upoštevajte, da povezave do YouTuba niso podprte za video možnosti."
    },
    text: {
      size: "Ta nastavitev spremeni samo velikost vnosnega polja in ne vpliva na širino polja z vprašanjem. Če želite omejiti dolžino vnesenega besedila, pojdite na \"Validacija\" → \"Največje dovoljeno število znakov\"."
    },
    comment: {
      rows: "Določa število prikazanih vrstic v vnosnem polju. Če vnos zavzame več vrstic, se prikaže drsna vrstica."
    },
    // survey templates
    survey: {
      mode: "Izberite med: »Urejajo« - omogoča respondentom, da izpolnijo vašo anketo; »Samo za branje« - onemogoči urejanje obrazca."
    },
    matrixdropdowncolumn: {
      name: "ID stolpca, ki ni viden anketirancem.",
      isUnique: "Ko je omogočeno za stolpec, mora anketiranec zagotoviti edinstven odgovor za vsako vprašanje v tem stolpcu.",
      rows: "Določi število prikazanih vrstic v vnosnem polju. Če vnos zavzame več vrstic, se bo prikazala drsna vrstica.",
      visibleIf: "Uporabite ikono čarobne palice za nastavitev pogojnega pravila, ki določi vidnost stolpca.",
      enableIf: "Uporabite ikono čarobne palice za nastavitev pogojnega pravila, ki onemogoči način samo za branje za stolpec.",
      requiredIf: "Uporabite ikono čarobne palice za nastavitev pogojnega pravila, ki prepreči oddajo ankete, če vsaj eno vprašanju v tem stolpcu ni bilo odgovorjeno.",
      showInMultipleColumns: "Ko je izbrano, ustvari posamezen stolpec za vsako izbiro.",
      colCount: "Razvrsti možnosti izbire v postavitev z več stolpci. Ko je nastavljeno na 0, so možnosti prikazane v eni sami vrstici. Ko je nastavljeno na -1, se dejanska vrednost podeduje iz lastnosti \"Število gnezdenih stolpcev\" nadrejenega matriksa."
    },
    caseInsensitive: "Izberite, če naj bodo velike in male črke v regularnem izrazu obravnavane kot enake",
    widthMode: "Izberite med Statistična - nastavi fiksno širino, Responsive - naredi anketo, da zasede celotno širino zaslona, Auto - uporabi eno izmed dveh možnosti glede na vrsto vprašanj",
    cookieName: "Dodelite edinstveno vrednost piškotka za vašo anketo. Piškotek bo nastavljen v brskalniku anketiranca ob zaključku ankete, da se prepreči ponovljeno oddajanje ankete",
    logo: "Prilepite povezavo do slike (brez omejitve velikosti) ali kliknite ikono mape, da poiščete datoteko na vašem računalniku (do 64KB)",
    logoWidth: "Nastavi širino logotipa v enotah CSS (px, %, in, pt itd.)",
    logoHeight: "Nastavi višino logotipa v enotah CSS (px, %, in, pt itd.)",
    logoFit: "Izberite med: Brez - slika ohrani svojo izvirno velikost, Vsebuje - slika se preuredi, da se prilega obdržanju svojega razmerja stranic, Pokriva - slika zapolni celoten okvir obdržanju svojega razmerja stranic, Polni - slika se raztegne, da zapolni okvir brez ohranjanja razmerja stranic",
    goNextPageAutomatic: "Izberite, ali želite, da se anketa samodejno premakne na naslednjo stran, ko anketiranec odgovori na vsa vprašanja na trenutni strani. Ta funkcija ne bo veljala, če je zadnje vprašanje na strani odprto ali omogoča več odgovorov.",
    allowCompleteSurveyAutomatic: "Izberite, ali želite, da se anketa izpolni samodejno, ko respondent odgovori na vsa vprašanja.",
    showNavigationButtons: "Nastavi vidnost gumbov za navigacijo na strani",
    showProgressBar: "Nastavi vidnost in mesto vrstice napredovanja. Vrednost »Samodejno« prikaže vrstico napredka nad ali pod glavo ankete.",
    showPreviewBeforeComplete: "Omogoči predogledno stran z vsemi ali samo odgovorjenimi vprašanji",
    questionTitleLocation: "Uporabi za vsa vprašanja v anketi. To nastavitev je mogoče nadomestiti z nastavitvami poravnave naslovov na nižjih ravneh: panel, stran ali vprašanje. Nastavitve na nižji ravni preglasijo tiste na višji ravni",
    requiredText: "Simbol ali zaporedje simbolov, ki označujejo, da je potreben odgovor.",
    questionStartIndex: "Vnesite številko ali črko, s katero želite začeti številčenje",
    questionErrorLocation: "Nastavi lokacijo sporočila o napaki v povezavi z vprašanjem z neveljavnim vnosom. Izberite med: Na vrhu - sporočilo o napaki je postavljeno na vrh vprašanja, Na dnu - sporočilo o napaki je postavljeno na dno vprašanja",
    focusFirstQuestionAutomatic: "Izberite, ali želite, da je prvo vnosno polje na vsaki strani pripravljeno za vnos besedila.",
    questionsOrder: "Ohrani prvotni vrstni red vprašanj ali jih naključno razvrsti. Učinek te nastavitve je viden samo na zavihku Predogled.",
    maxTextLength: "Samo za vprašanja z besedilnim vnosom",
    maxOthersLength: "Samo za komentarje vprašanj.",
    commentAreaRows: "Nastavi število prikazanih vrstic v besedilnih območjih za komentarje vprašanj. Če vnos zavzame več vrstic, se prikaže drsni trak.",
    autoGrowComment: "Izberite, če želite, da se komentarji k vprašanjem in vprašanja z dolgim besedilom samodejno povečajo v višino glede na dolžino vnesenega besedila",
    allowResizeComment: "Samo za komentarje k vprašanjem in vprašanja z dolgim besedilom",
    calculatedValues: "Spremenljivke po meri služijo kot vmesne ali pomožne spremenljivke, ki se uporabljajo v izračunih obrazca. Uporabljajo vhodne vrednosti anketirancev. Vsaka spremenljivka po meri ima edinstveno ime in izraz, na katerem temelji",
    includeIntoResult: "Izberite, če želite, da se izračunana vrednost izraza shrani skupaj z rezultati ankete",
    triggers: "Sprožilec je dogodek ali pogoj, ki temelji na izrazu. Ko je izraz ocenjen kot resničen, sprožilec izvede akcijo. Ta akcija lahko po potrebi vpliva na ciljno vprašanje",
    clearInvisibleValues: "Izberite, ali želite ali ne želite počistiti vrednosti za vprašanja, ki so skrita zaradi pogojne logike, in kdaj to storiti",
    textUpdateMode: "Izberite med: Ob izgubi fokusa - vrednost se posodobi, ko vnosno polje izgubi fokus, Med tipkanjem - vrednost se posodobi v realnem času, ko uporabniki tipkajo",
    columns: "Leva vrednost služi kot ID stolpca, ki se uporablja v pogojnih pravilih, desna vrednost pa je prikazana anketirancem",
    rows: "Leva vrednost služi kot ID vrstice, ki se uporablja v pogojnih pravilih, desna vrednost pa je prikazana anketirancem",
    columnMinWidth: "Sprejme enote CSS (px, %, in, pt itd.)",
    rowTitleWidth: "Sprejme enote CSS (px, %, in, pt itd.)",
    totalText: "Vidno je samo, če vsaj en stolpec prikazuje skupne vrednosti, nastavljene z: Metoda agregacije ali Izraz skupne vrednosti",
    cellErrorLocation: "Nastavi lokacijo sporočila o napaki v povezavi s celico z neveljavnim vnosom. Nastavitev Podedovano uporablja nastavitev iz lastnosti: Poravnava sporočila o napaki",
    keyDuplicationError: "Ko je omogočena lastnost Prepreči podvojene odgovore, bo anketiranec, ki bo poskušal oddati podvojen vnos, prejel naslednje sporočilo o napaki",
    totalExpression: "Omogoča izračun skupnih vrednosti na podlagi izraza. Izraz lahko vključuje: osnovne izračune ({q1_id} + {q2_id}), Booleove izraze ({age} > 60) in funkcije (iif(), today(), age(), min(), max(), avg() itd.)",
    confirmDelete: "Sproži poziv za potrditev brisanja vrstice",
    defaultValueFromLastRow: "Podvoji odgovore iz zadnje vrstice in jih dodeli naslednji dodani dinamični vrstici.",
    keyName: "Sklicujte se na ID stolpca, da zahtevate od uporabnika, da zagotovi edinstven odgovor za vsako vprašanje znotraj določenega stolpca",
    description: "Vnesite podnaslov",
    locale: "Izberite jezik za začetek ustvarjanja vaše ankete. Če želite dodati prevod, preklopite na nov jezik in prevedite izvirno besedilo tukaj ali na zavihku Prevod",
    detailPanelMode: "Nastavi lokacijo podrobnostnega razdelka v povezavi z vrstico. Izberite med: Brez - ni dodanega širjenja, Pod vrstico - širitev vrstice je postavljena pod vsako vrstico matrike, Pod vrstico prikaži samo eno širitev vrstice - širitev je prikazana samo pod eno vrstico, preostale širitve vrstic so skrite",
    imageFit: "Izbirate lahko med: \"Brez\" - slika ohrani svojo prvotno velikost; »Contain« - velikost slike se spremeni tako, da se prilega, hkrati pa se ohrani njeno razmerje; »Cover« - slika zapolni celotno škatlo, hkrati pa ohrani razmerje med širino in višino; »Polnilo« - slika se raztegne, da zapolni polje, ne da bi ohranila njegovo razmerje.",
    autoGrow: "Možnost »Podeduj« uporablja nastavitev na ravni ankete (»privzeto onemogočeno«).",
    allowResize: "Možnost »Deduj« uporablja nastavitev na ravni ankete (»privzeto omogočeno«).",
    timeLimit: "Časovni interval v sekundah, po katerem anketa samodejno preide na stran Hvala. Ko je nastavljeno na 0, šteje čas preživet na anketi",
    timeLimitPerPage: "Časovni interval v sekundah, po katerem se anketa samodejno premakne na naslednjo stran. Skrije gumb za krmarjenje »Prejšnji«. Če je nastavljena na 0, šteje čas, porabljen na trenutni strani.",
    validateVisitedEmptyFields: "Omogočite to možnost, da sprožite preverjanje, ko uporabnik klikne na prazno vnosno polje in ga nato zapusti brez sprememb",
    page: {
      timeLimit: "Časovni interval v sekundah, po katerem anketa samodejno preide na naslednjo stran. Skrije navigacijski gumb Prejšnja. Ko je nastavljeno na 0, šteje čas preživet na trenutni strani",
      visibleIf: "Uporabite ikono čarobne palice za nastavitev pogoja, ki določa vidnost strani",
      enableIf: "Uporabite ikono čarobne palice za nastavitev pogoja, ki onemogoči način samo za branje na strani",
      requiredIf: "Uporabite ikono čarobne palice za nastavitev pogoja, ki prepreči oddajo ankete, razen če ima vsaj eno gnezdeno vprašanje odgovor",
      questionTitleLocation: "Uporablja se za vsa vprašanja na tej strani. Ko je nastavljeno na Skrito, skrije tudi opise vprašanj. Če želite preglasiti to nastavitev, določite pravila poravnave naslovov za posamezna vprašanja ali panele. Možnost Podedovano uporabi nastavitev na ravni ankete (privzeto Zgoraj)",
      questionTitleWidth: "Nastavi enotno širino naslovov vprašanj, ko so poravnani na levo od svojih vprašalnih polj. Sprejema CSS vrednosti (px, %, in, pt itd.)",
      questionErrorLocation: "Nastavi lokacijo sporočila o napaki v povezavi z vprašanjem z neveljavnim vnosom. Izberite med: Zgoraj - besedilo napake je postavljeno na vrh polja vprašanja, Spodaj - besedilo napake je postavljeno na dno polja vprašanja. Možnost Podedovano uporabi nastavitev na ravni ankete (privzeto Zgoraj)",
      questionsOrder: "Ohrani prvotni vrstni red vprašanj ali jih naključno razvrsti. Možnost »Deduj« uporablja nastavitev na ravni ankete (privzeto »Izvirno«). Učinek te nastavitve je viden samo na zavihku Predogled.",
      navigationButtonsVisibility: "Nastavi vidnost gumbov za krmarjenje na strani. Možnost »Deduj« uporablja nastavitev na ravni ankete, ki je privzeto »Vidno«."
    },
    timerLocation: "Nastavi lokacijo časovnika na strani",
    panelsState: "Izberite med: Zaklenjeno - uporabniki ne morejo razširiti ali strniti panelov, Strni vse - vsi paneli se začnejo v strnjenem stanju, Razširi vse - vsi paneli se začnejo v razširjenem stanju, Prvi razširjen - samo prvi panel je sprva razširjen. Uporablja se, če je Način prikaza panela nastavljen na Seznam in je določena lastnost Vzorec naslova panela",
    imageLinkName: "Vnesite skupno ime lastnosti v nizu objektov, ki vsebuje URL-je slik ali video datotek, ki jih želite prikazati na seznamu izbir",
    choices: "Leva vrednost služi kot ID elementa, ki se uporablja v pogojnih pravilih, desna vrednost je prikazana anketirancem",
    title: "Vnesite uporabniku prijazen naslov za prikaz",
    waitForUpload: "Zagotavlja, da uporabniki ne morejo dokončati ankete, dokler datoteke niso naložene",
    minWidth: "Sprejema CSS vrednosti (px, %, in, pt itd.)",
    maxWidth: "Sprejema CSS vrednosti (px, %, in, pt itd.)",
    width: "Sprejema CSS vrednosti (px, %, in, pt itd.)",
    valueName: "Identifikator združevanja je uporabniški ključ, ki ga lahko dodelite več vprašanjem, da jih povežete in sinhronizirate njihove vrednosti. Te vrednosti bodo združene v en niz ali objekt in shranjene v rezultatih ankete z uporabo ključa kot imena lastnosti",
    useDisplayValuesInDynamicTexts: "Pri vprašanjih z eno ali več izbirami ima vsaka možnost ID in prikazano vrednost. Ko je izbrano, ta nastavitev prikaže prikazano vrednost namesto ID-ja v HTML vprašanjih ter dinamičnih naslovih in opisih anketnih elementov",
    clearIfInvisible: "Izberite, ali naj se vrednosti vprašanj, skritih s pogojno logiko, izbrišejo in kdaj naj se to zgodi. Možnost Podedovano uporabi nastavitev na ravni ankete (privzeto Ob zaključku ankete)",
    choicesFromQuestionMode: "Izberite med: Vse - kopira vse možnosti izbranega vprašanja, Izbrano - dinamično kopira samo izbrane možnosti, Neizbrano - dinamično kopira samo neizbrane možnosti. Možnosti Nobena in Drugo se kopirajo privzeto, če so omogočene v izvornih vprašanjih",
    showOtherItem: "Ko je izbrano, lahko uporabniki vnesejo dodatni vnos v ločeno polje za komentar",
    separateSpecialChoices: "Vsako posebno možnost (Nobena, Drugo, Izberi vse) prikaže v novi vrstici, tudi pri uporabi večstolpčne postavitve",
    path: "Določite lokacijo znotraj niza podatkov storitve, kjer se nahaja ciljni niz objektov. Pustite prazno, če URL že kaže na niz",
    choicesbyurl: {
      valueName: "Vnesite enotno ime lastnosti znotraj niza objektov, katere vrednost bo shranjena kot odgovor v rezultatih ankete"
    },
    titleName: "Vnesite enotno ime lastnosti znotraj niza objektov, ki vsebuje vrednosti, ki jih želite prikazati na seznamu izbir",
    allowEmptyResponse: "Izberite to možnost, da omogočite storitvi vračanje praznega odziva ali niza",
    choicesVisibleIf: "Uporabite ikono čarobne palice za nastavitev pogoja, ki določa vidnost vseh možnosti izbire",
    rateValues: "Leva vrednost služi kot ID elementa, ki se uporablja v pogojnih pravilih, desna vrednost je prikazana anketirancem",
    rating: {
      displayMode: "\"Samodejno\" izbere med načinoma \"Gumbi\" in \"Spustni seznam\" glede na razpoložljivo širino. Ko širina ni zadostna za prikaz gumbov, se vprašanje prikaže kot spustni seznam"
    },
    valuePropertyName: "Omogoča povezovanje vprašanj, ki ustvarjajo rezultate v različnih formatih. Ko so taka vprašanja povezana z identifikatorjem združevanja, ta skupna lastnost shrani izbrane vrednosti vprašanj",
    searchEnabled: "Izberite, če želite, da se vsebina spustnega seznama posodobi glede na iskalni niz, ki ga uporabnik vnaša v polje za vnos",
    valueTrue: "Vrednost, ki se shrani v rezultate ankete, ko anketiranec poda pozitiven odgovor",
    valueFalse: "Vrednost, ki se shrani v rezultate ankete, ko anketiranec poda negativen odgovor",
    showPreview: "Ni priporočljivo, da onemogočite to možnost, saj preglasi predogled slike in uporabniku oteži razumevanje, ali so bile datoteke naložene",
    needConfirmRemoveFile: "Sproži poziv za potrditev brisanja datoteke",
    selectToRankEnabled: "Omogoči razvrščanje samo izbranih možnosti. Uporabniki bodo povlekli izbrane elemente s seznama možnosti in jih razvrstili v območju za razvrščanje",
    dataList: "Vnesite seznam možnosti, ki bodo predlagane anketirancu med vnosom",
    itemSize: "Nastavitev spremeni samo velikost vhodnih polj in ne vpliva na širino polja z vprašanji.",
    itemTitleWidth: "Nastavi enotno širino vseh oznak elementov v slikovnih pikah",
    inputTextAlignment: "Izberite način poravnave vnosne vrednosti znotraj polja. Privzeta nastavitev \"Samodejno\" poravna vnosno vrednost desno, če je uporabljeno oblikovanje valute ali številk, in levo, če ni",
    altText: "Služi kot nadomestek, ko slike ni mogoče prikazati na uporabnikovi napravi, in za dostopnost",
    rateColorMode: "Določa barvo izbrane ikone smeška, ko je vrsta ocenjevalne ikone nastavljena na \"Smeški\". Izberite med: \"Privzeto\" - izbrani smeško se prikaže v privzeti barvi ankete; \"Lestvica\" - izbrani smeško podeduje barvo iz ocenjevalne lestvice",
    expression: {
      name: "ID izraza, ki ni viden anketirancem",
      description: "Vnesite podnaslov izraza",
      expression: "Izraz lahko vključuje osnovne izračune ({q1_id} + {q2_id}), pogoje ({age} > 60) in funkcije (iif(), today(), age(), min(), max(), avg(), itd.)"
    },
    storeOthersAsComment: "Izberite, da shranite vrednost možnosti \"Drugo\" kot ločeno lastnost v rezultatih ankete",
    format: "Uporabite {0} kot nadomestni znak za dejansko vrednost",
    acceptedTypes: "Poglejte opis atributa accept za več informacij",
    columnColCount: "Razporedi možnosti izbire v več-stolpčni postavitvi. Ko je nastavljen na 0, so možnosti prikazane v eni vrstici. Velja samo za stolpce, kjer je \"Vrsta vnosa celice\" nastavljena na Skupino gumbov za izbiro ali potrditvena polja",
    autocomplete: "Izberite vrsto podatkov, ki jih lahko brskalnik uporabnika pridobi. Ti podatki so pridobljeni bodisi iz prejšnjih vrednosti, ki jih je uporabnik vnesel, bodisi iz prednastavljenih vrednosti, če jih je uporabnik shranil za samodejno dopolnjevanje",
    filePlaceholder: "Velja, ko je \"Vrsta vira datoteke\" nastavljena na \"Lokalna datoteka\" ali ko kamera ni na voljo",
    photoPlaceholder: "Velja, ko je \"Vrsta vira datoteke\" nastavljena na \"Kamera\"",
    fileOrPhotoPlaceholder: "Velja, ko je \"Vrsta vira datoteke\" nastavljena na \"Lokalna datoteka ali kamera\"",
    colCount: "Razporedi možnosti izbire v več-stolpčni postavitvi. Ko je nastavljen na 0, so možnosti prikazane v eni vrstici",
    masksettings: {
      saveMaskedValue: "Izberite, če želite shraniti vrednost vprašanja z uporabo maske v rezultatih ankete"
    },
    patternmask: {
      pattern: "Vzorec lahko vsebuje dobesedne nize in naslednje nadomestne znake: 9 - za številko; a - za veliko ali malo črko; # - za številko ali veliko ali malo črko. Za pobeg znaka uporabite poševnico \\."
    },
    datetimemask: {
      pattern: "Vzorec lahko vsebuje ločevalne znake in naslednje nadomestne znake:<br>m - številka meseca.<br>mm - številka meseca, z začetno ničlo za enomestne vrednosti.<br>d - dan v mesecu.<br>dd - dan v mesecu, z začetno ničlo za enomestne vrednosti.<br>yy - zadnji dve števki leta.<br>yyyy - štirimestno leto.<br>H - ure v 24-urnem formatu.<br>HH - ure v 24-urnem formatu, z začetno ničlo za enomestne vrednosti.<br>h - ure v 12-urnem formatu.<br>hh - ure v 12-urnem formatu, z začetno ničlo za enomestne vrednosti.<br>MM - minute.<br>ss - sekunde.<br>TT - obdobje 12-urnega ure v velikih črkah (AM/PM).<br>tt - obdobje 12-urnega ure v malih črkah (am/pm)."
    },
    numericmask: {
      decimalSeparator: "Simbol, ki se uporablja za ločevanje decimalnega dela od celostnega dela prikazane številke.",
      thousandsSeparator: "Simbol, ki se uporablja za ločevanje števk velike številke v skupine po tri.",
      precision: "Omejuje, koliko števk se ohrani po decimalni piki za prikazano številko."
    },
    currencymask: {
      prefix: "En ali več simbolov, ki bodo prikazani pred vrednostjo.",
      suffix: "En ali več simbolov, ki bodo prikazani za vrednostjo."
    },
    theme: {
      isPanelless: "Ta nastavitev velja samo za vprašanja, ki niso v okviru panela.",
      primaryColor: "Nastavi pomožno barvo, ki poudari ključne elemente ankete.",
      panelBackgroundTransparency: "Prilagodi prosojnost panelov in polj vprašanj glede na ozadje ankete.",
      questionBackgroundTransparency: "Prilagodi prosojnost vhodnih elementov glede na ozadje ankete.",
      cornerRadius: "Nastavi radij kota za vse pravokotne elemente. Omogočite napreden način, če želite nastaviti posamezne vrednosti radija kota za vhodne elemente ali panele in polja vprašanj.",
      "--sjs-general-backcolor-dim": "Nastavi glavno ozadje ankete."
    },
    header: {
      inheritWidthFrom: "\"Enako kot kontejner\" možnost samodejno prilagodi širino vsebine glave, da se prilega HTML elementu, v katerega je anketa vstavljena.",
      textAreaWidth: "Širina območja glave, ki vsebuje naslov in opis ankete, izmerjena v slikovnih pikah.",
      overlapEnabled: "Ko je omogočeno, zgornji del ankete prekrije spodnji del glave.",
      mobileHeight: "Ko je nastavljeno na 0, se višina samodejno izračuna, da sprejme vsebino glave."
    },
    panellayoutcolumn: {
      effectiveWidth: "Sprejema vrednosti %.",
      questionTitleWidth: "Sprejema vrednosti px."
    },
    progressBarInheritWidthFrom: "\"Enako kot kontejner\" možnost samodejno prilagodi širino območja napredka, da se prilega HTML elementu, v katerega je anketa vstavljena."
  },
  // Properties
  p: {
    title: {
      name: "naslov",
      title: "Pustite prazno, če je enako kot 'Ime'"
    },
    multiSelect: "Dovoli večkratno izbiro",
    showLabel: "Prikaži podnapise za slike in videoposnetke",
    swapOrder: "Zamenjaj vrstni red Da in Ne",
    value: "Vrednost",
    tabAlign: "Poravnava zavičkov",
    sourceType: "Vrsta vira datotek",
    fitToContainer: "Prilagodi vsebino za vsak prostor",
    setValueExpression: "Nastavi izraz vrednosti",
    description: "Opis",
    logoFit: "Prilagajanje logotipa",
    pages: "Strani",
    questions: "Vprašanja",
    triggers: "Sprožilci",
    calculatedValues: "Prilagojene spremenljivke",
    surveyId: "ID ankete",
    surveyPostId: "ID objave ankete",
    surveyShowDataSaving: "Prikaz shranjevanja podatkov ankete",
    questionDescriptionLocation: "Poravnava opisa vprašanja",
    progressBarType: "Vrsta napredovanja",
    showTOC: "Prikaz vsebine (TOC)",
    tocLocation: "Poravnava vsebine",
    questionTitlePattern: "Vzorec naslova vprašanja",
    widthMode: "Način širine ankete",
    showBrandInfo: "Prikaz informacij o blagovni znamki",
    useDisplayValuesInDynamicTexts: "Uporabi prikazane vrednosti v dinamičnih besedilih",
    visibleIf: "Vidno, če",
    defaultValueExpression: "Privzeti izraz vrednosti",
    requiredIf: "Obvezno, če",
    resetValueIf: "Ponastavi vrednost, če",
    setValueIf: "Nastavi vrednost, če",
    validators: "Pravila za preverjanje",
    bindings: "Povezave",
    renderAs: "Upodobi kot",
    attachOriginalItems: "Poveži izvirne predmete",
    choices: "Izbire",
    choicesByUrl: "Izbire prek URL-ja",
    currency: "Valuta",
    cellHint: "Namig za celico",
    totalMaximumFractionDigits: "Skupaj največje decimalne številke",
    totalMinimumFractionDigits: "Skupaj najmanjše decimalne številke",
    columns: "Stolpci",
    detailElements: "Podrobnosti elementov",
    allowAdaptiveActions: "Dovoli prilagodljive akcije",
    defaultRowValue: "Privzeta vrednost vrstice",
    detailPanelShowOnAdding: "Prikaz panela podrobnosti ob dodajanju",
    choicesLazyLoadEnabled: "Omogoči počasno nalaganje izbir",
    choicesLazyLoadPageSize: "Velikost strani za počasno nalaganje izbir",
    inputFieldComponent: "Komponenta vhodnega polja",
    itemComponent: "Komponenta predmeta",
    min: "Min",
    max: "Max",
    minValueExpression: "Izraz najmanjše vrednosti",
    maxValueExpression: "Izraz največje vrednosti",
    step: "Korak",
    dataList: "Predmeti za samodejni predlog",
    itemSize: "Širina vnosnega polja (v znakih)",
    itemTitleWidth: "Širina oznake predmeta (v px)",
    inputTextAlignment: "Poravnava vnosa",
    elements: "Elementi",
    content: "Vsebina",
    navigationButtonsVisibility: "Pokaži/skrij gumbe za krmarjenje",
    navigationTitle: "Naslov navigacije",
    navigationDescription: "Opis navigacije",
    longTap: "Dolgo tapkanje",
    autoGrow: "Samodejno širjenje vhodnega polja",
    allowResize: "Omogoči ročico za spreminjanje velikosti",
    acceptCarriageReturn: "Sprejmi prenos vrstice",
    displayMode: "Način prikaza",
    rateType: "Vrsta ocene",
    label: "Oznaka",
    contentMode: "Način vsebine",
    imageFit: "Prilagoditev slike in sličice",
    altText: "Alternativno besedilo",
    height: "Višina",
    mobileHeight: "Višina na pametnih telefonih",
    penColor: "Barva peresa",
    backgroundColor: "Barva ozadja",
    templateElements: "Elementi predloge",
    operator: "Operater",
    isVariable: "Je spremenljivka",
    runExpression: "Zaženi izraz",
    showCaption: "Prikaz besedila",
    iconName: "Ime ikone",
    iconSize: "Velikost ikone",
    precision: "Natančnost",
    matrixDragHandleArea: "Območje za povleci ročaj matrične tabele",
    backgroundImage: "Slika ozadja",
    backgroundImageFit: "Prilagoditev slike ozadja",
    backgroundImageAttachment: "Pripenjanje slike ozadja",
    backgroundOpacity: "Prosojnost ozadja",
    selectToRankEnabled: "Omogoči selektivno razvrščanje",
    selectToRankAreasLayout: "Poravnava območij za razvrščanje",
    selectToRankEmptyRankedAreaText: "Besedilo, ki se prikaže, če so vse možnosti izbrane",
    selectToRankEmptyUnrankedAreaText: "Besedilni prostor za območje razvrščanja",
    allowCameraAccess: "Dovoli dostop do kamere",
    scaleColorMode: "Način barve ikone za oceno",
    rateColorMode: "Barvna shema smeškotov",
    copyDisplayValue: "Kopiraj prikazano vrednost",
    effectiveColSpan: "Razpon stolpca",
    progressBarInheritWidthFrom: "Širina območja napredka"
  },
  theme: {
    advancedMode: "Napreden način",
    pageTitle: "Stran",
    questionTitle: "Polje vprašanja",
    editorPanel: "Vhodni element",
    lines: "Vrste",
    primaryDefaultColor: "Privzeto",
    primaryDarkColor: "Ob prehodu",
    primaryLightColor: "Izbrano",
    backgroundDimColor: "Barva ozadja",
    cornerRadius: "Radij kota",
    backcolor: "Privzeto ozadje",
    hovercolor: "Ozadje ob prehodu",
    borderDecoration: "Dekoracija robov",
    fontColor: "Barva pisave",
    backgroundColor: "Barva ozadja",
    primaryForecolor: "Privzeta barva",
    primaryForecolorLight: "Onemogočena barva",
    font: "Pisava",
    borderDefault: "Temnejši rob",
    borderLight: "Svetlejši rob",
    fontFamily: "Družina pisav",
    fontWeightRegular: "Običajno",
    fontWeightHeavy: "Težka",
    fontWeightSemiBold: "Polkrepko",
    fontWeightBold: "Krepko",
    color: "Barva",
    placeholderColor: "Barva oznake",
    size: "Velikost",
    opacity: "Prosojnost",
    boxShadowX: "X",
    boxShadowY: "Y",
    boxShadowAddRule: "Dodaj učinek sence",
    boxShadowBlur: "Zameglitev",
    boxShadowSpread: "Porazdelitev",
    boxShadowDrop: "Padec",
    boxShadowInner: "Znotraj",
    names: {
      default: "Privzeto",
      sharp: "Ostrina",
      borderless: "Brez roba",
      flat: "Raven",
      plain: "Preprost",
      doubleborder: "Dvojni rob",
      layered: "Slojevit",
      solid: "Trd",
      threedimensional: "3D",
      contrast: "Kontrast"
    },
    colors: {
      teal: "Turkizna",
      blue: "Modra",
      purple: "Vijolična",
      orchid: "Orhideja",
      tulip: "Tulipan",
      brown: "Rjava",
      green: "Zelena",
      gray: "Siva"
    }
  },
  creatortheme: {
    "--sjs-special-background": "Ozadje površine",
    "--sjs-primary-background-500": "Primarno",
    "--sjs-secondary-background-500": "Sekundarno",
    surfaceScale: "Površina",
    userInterfaceBaseUnit: "Enote uporabniškega vmesnika",
    fontScale: "Pisava",
    names: {
      sc2020: "Ustvarjalec anket 2020",
      "default-light": "Svetla",
      "default-dark": "Temna",
      "default-contrast": "Kontrast"
    }
  }
};

setupLocale({ localeCode: "sl", strings: slStrings });
// The following strings have been translated by a machine translation service
// Remove those strings that you have corrected manually
// tabs.editor: "JSON Editor" => "Urejevalnik JSON"
// panel.questionsOrder: "Question order within the panel" => "Vrstni red vprašanj v odboru"
// paneldynamic.hideNumber: "Hide the panel number" => "Skrivanje številke plošče"
// paneldynamic.templateTitleLocation: "Question title alignment" => "Poravnava naslova vprašanja"
// paneldynamic.showProgressBar: "Show the progress bar" => "Prikaz vrstice napredovanja"
// paneldynamic.progressBarLocation: "Progress bar alignment" => "Poravnava vrstice napredovanja"
// question.hideNumber: "Hide the question number" => "Skrivanje številke vprašanja"
// page.questionsOrder: "Question order on the page" => "Vrstni red vprašanj na strani"
// pe.isAllRowRequired: "Require an answer in each row" => "Zahtevajte odgovor v vsaki vrstici"
// pe.addRowLocation: "\"Add Row\" button alignment" => "Poravnava gumba »Dodaj vrstico«"
// pe.mode: "Survey display mode" => "Način prikaza ankete"
// pe.sendResultOnPageNext: "Auto-save survey progress on page change" => "Samodejno shranjevanje napredka ankete ob spremembi strani"
// pe.firstPageIsStarted: "First page is a start page" => "Prva stran je začetna stran"
// pe.showCompletedPage: "Show the \"Thank You\" page" => "Prikaz strani »Hvala«"
// pe.goNextPageAutomatic: "Auto-advance to the next page" => "Samodejno pomikanje na naslednjo stran"
// pe.allowCompleteSurveyAutomatic: "Complete the survey automatically" => "Samodejno izpolnite anketo"
// pe.requiredText: "Required symbol(s)" => "Zahtevani simbol(i)"
// pe.focusFirstQuestionAutomatic: "Focus first question on a new page" => "Osredotočite se na prvo vprašanje na novi strani"
// pe.questionsOrder: "Question order" => "Vrstni red vprašanj"
// pe.panelAddText: "\"Add Panel\" button text" => "Besedilo gumba »Dodaj ploščo«"
// pe.panelRemoveText: "\"Remove Panel\" button text" => "Besedilo gumba »Odstrani ploščo«"
// pe.allowRowsDragAndDrop: "Enable row reordering" => "Omogočanje spreminjanja vrstic"
// pe.maxOthersLength: "Restrict comment length" => "Omejitev dolžine komentarja"
// pe.focusOnFirstError: "Set focus on the first invalid answer" => "Nastavitev fokusa na prvi neveljaven odgovor"
// pe.panelPrevText: "\"Previous Panel\" button text" => "Besedilo gumba »Prejšnja plošča«"
// pe.panelNextText: "\"Next Panel\" button text" => "Besedilo gumba »Naslednja plošča«"
// pe.panelRemoveButtonLocation: "\"Remove Panel\" button alignment" => "Poravnava gumba »Odstrani ploščo«"
// pe.showClearButton: "Show the Clear button" => "Pokaži gumb »Počisti«"
// pe.emptyRowsText: "\"No rows\" message" => "Sporočilo »Brez vrstic«"
// pe.rowsOrder: "Row order" => "Vrstni red vrstic"
// pe.defaultValueFromLastRow: "Use answers from the last row as default" => "Privzeto uporabi odgovore iz zadnje vrstice"
// pe.defaultValueFromLastPanel: "Use answers from the last panel as default" => "Privzeto uporabi odgovore iz zadnje plošče"
// addRowLocation.default: "Based on matrix layout" => "Na podlagi postavitve matrike"
// rowsOrder.initial: "Original" => "Izvirno"
// questionsOrder.initial: "Original" => "Izvirno"
// showProgressBar.off: "Hidden" => "Skriven"
// showProgressBar.topbottom: "Top and bottom" => "Zgornji in spodnji del"
// showProgressBar.aboveheader: "Above the header" => "Nad glavo"
// showProgressBar.belowheader: "Below the header" => "Pod glavo"
// progressBarLocation.topBottom: "Top and bottom" => "Zgornji in spodnji del"
// panel.questionsOrder: "Keeps the original order of questions or randomizes them. The \"Inherit\" option applies the page-level (if set) or survey-level setting." => "Ohrani prvotni vrstni red vprašanj ali jih naključno razvrsti. Možnost »Podeduj« uporablja nastavitev na ravni strani (če je nastavljena) ali na ravni ankete."
// paneldynamic.templateTitleLocation: "Applies to all questions within this panel. If you want to override this setting, define title alignment rules for individual questions. The \"Inherit\" option applies the page-level (if set) or survey-level setting (\"Top\" by default)." => "Velja za vsa vprašanja v tej plošči. Če želite preglasiti to nastavitev, določite pravila za poravnavo naslovov za posamezna vprašanja. Možnost »Podeduj« uporablja nastavitev na ravni strani (če je nastavljena) ali na ravni ankete (privzeto »na vrh«)."
// paneldynamic.defaultValueFromLastPanel: "Duplicates answers from the last panel and assigns them to the next added dynamic panel." => "Podvoji odgovore iz zadnje plošče in jih dodeli naslednji dodani dinamični plošči."
// survey.mode: "Choose between: \"Editable\" - enables respondents to fill out your survey; \"Read-only\" - disables form editing." => "Izberite med: »Urejajo« - omogoča respondentom, da izpolnijo vašo anketo; »Samo za branje« - onemogoči urejanje obrazca."
// pehelp.goNextPageAutomatic: "Select if you want the survey to auto-advance to the next page once a respondent has answered all questions on the current page. This feature won't apply if the last question on the page is open-ended or allows multiple answers." => "Izberite, ali želite, da se anketa samodejno premakne na naslednjo stran, ko anketiranec odgovori na vsa vprašanja na trenutni strani. Ta funkcija ne bo veljala, če je zadnje vprašanje na strani odprto ali omogoča več odgovorov."
// pehelp.allowCompleteSurveyAutomatic: "Select if you want the survey to complete automatically after a respondent answers all questions." => "Izberite, ali želite, da se anketa izpolni samodejno, ko respondent odgovori na vsa vprašanja."
// pehelp.showProgressBar: "Sets the visibility and location of a progress bar. The \"Auto\" value displays the progress bar above or below the survey header." => "Nastavi vidnost in mesto vrstice napredovanja. Vrednost »Samodejno« prikaže vrstico napredka nad ali pod glavo ankete."
// pehelp.requiredText: "A symbol or a sequence of symbols indicating that an answer is required." => "Simbol ali zaporedje simbolov, ki označujejo, da je potreben odgovor."
// pehelp.focusFirstQuestionAutomatic: "Select if you want the first input field on each page ready for text entry." => "Izberite, ali želite, da je prvo vnosno polje na vsaki strani pripravljeno za vnos besedila."
// pehelp.questionsOrder: "Keeps the original order of questions or randomizes them. The effect of this setting is only visible in the Preview tab." => "Ohrani prvotni vrstni red vprašanj ali jih naključno razvrsti. Učinek te nastavitve je viden samo na zavihku Predogled."
// pehelp.maxOthersLength: "For question comments only." => "Samo za komentarje vprašanj."
// pehelp.defaultValueFromLastRow: "Duplicates answers from the last row and assigns them to the next added dynamic row." => "Podvoji odgovore iz zadnje vrstice in jih dodeli naslednji dodani dinamični vrstici."
// pehelp.imageFit: "Choose from: \"None\" - image maintains its original size; \"Contain\" - image is resized to fit while maintaining its aspect ratio; \"Cover\" - image fills the entire box while maintaining its aspect ratio; \"Fill\" - image is stretched to fill the box without maintaining its aspect ratio." => "Izbirate lahko med: \"Brez\" - slika ohrani svojo prvotno velikost; »Contain« - velikost slike se spremeni tako, da se prilega, hkrati pa se ohrani njeno razmerje; »Cover« - slika zapolni celotno škatlo, hkrati pa ohrani razmerje med širino in višino; »Polnilo« - slika se raztegne, da zapolni polje, ne da bi ohranila njegovo razmerje."
// pehelp.autoGrow: "The \"Inherit\" option applies a survey-level setting (\"Disabled\" by default)." => "Možnost »Podeduj« uporablja nastavitev na ravni ankete (»privzeto onemogočeno«)."
// pehelp.allowResize: "The \"Inherit\" option applies a survey-level setting (\"Enabled\" by default)." => "Možnost »Deduj« uporablja nastavitev na ravni ankete (»privzeto omogočeno«)."
// pehelp.timeLimitPerPage: "A time interval in seconds after which the survey auto-advances to the next page. Hides the \"Previous\" navigation button. When set to 0, counts the time spent on the current page." => "Časovni interval v sekundah, po katerem se anketa samodejno premakne na naslednjo stran. Skrije gumb za krmarjenje »Prejšnji«. Če je nastavljena na 0, šteje čas, porabljen na trenutni strani."
// page.questionsOrder: "Keeps the original order of questions or randomizes them. The \"Inherit\" option applies the survey-level setting (\"Original\" by default). The effect of this setting is only visible in the Preview tab." => "Ohrani prvotni vrstni red vprašanj ali jih naključno razvrsti. Možnost »Deduj« uporablja nastavitev na ravni ankete (privzeto »Izvirno«). Učinek te nastavitve je viden samo na zavihku Predogled."
// page.navigationButtonsVisibility: "Sets the visibility of navigation buttons on the page. The \"Inherit\" option applies the survey-level setting, which defaults to \"Visible\"." => "Nastavi vidnost gumbov za krmarjenje na strani. Možnost »Deduj« uporablja nastavitev na ravni ankete, ki je privzeto »Vidno«."
// pehelp.itemSize: "The setting only resizes the input fields and doesn't affect the width of the question box." => "Nastavitev spremeni samo velikost vhodnih polj in ne vpliva na širino polja z vprašanji."
// panellayoutcolumn.effectiveWidth: "Accepts values %." => "Sprejema vrednosti %."
// panellayoutcolumn.questionTitleWidth: "Accepts values px." => "Sprejema vrednosti px."
// p.itemSize: "Input field width (in characters)" => "Širina vnosnega polja (v znakih)"
// p.navigationButtonsVisibility: "Show/hide navigation buttons" => "Pokaži/skrij gumbe za krmarjenje"
// ed.addLanguageTooltip: "Add Language" => "Dodajanje jezika"
// pehelp.commentAreaRows: "Sets the number of displayed lines in text areas for question comments. If the input takes up more lines, the scroll bar appears." => "Nastavi število prikazanih vrstic v besedilnih območjih za komentarje vprašanj. Če vnos zavzame več vrstic, se prikaže drsni trak."