import React, { useState } from "react";
import { MsalContext, MsalProvider as MSALReactProvider, useMsal } from "@azure/msal-react";

// ExtendedMsalProvider: envuelve al provider original de MSAL e inyecta
// los nuevos campos emailContext y su setter.
export const ExtendedMsalProvider = ({ instance, children }) => {
// Controlamos el emailContext con useState.
const [emailContext, setEmailContext] = useState("");

return (


{children}


);
};

const MsalProviderWrapper = ({ children, emailContext, setEmailContext }) => {
// useMsal() devuelve los valores originales del contexto MSAL,
// como { instance, accounts, inProgress, logger }
const msalValues = useMsal();

// Combinamos los valores de MSAL con nuestros nuevos campos
const extendedContext = { ...msalValues, emailContext, setEmailContext };

return (
<MsalContext.Provider value={extendedContext}>
{children}
</MsalContext.Provider>
);
};
