import React, { useState, useEffect, useContext } from "react";  
import { connect } from "react-redux";  
import { MsalContext } from "@azure/msal-react";  
import { Alert, Box } from "@mui/material";  
import SurveySlackCard from "./survey-slack-card/survey-card";  
import axios from "axios";  
import {  
    deleteSurveyThunk,  
    getAllSurveysWithScheduleThunk,  
    getSingleSurveysWithQandAThunk,  
    getAllUserSurveysThunk  
} from "../../../redux/survey/survey.thunk";  
import { useEmailContext } from "../../../redux/EmailContext"; // Usa el custom hook directamente  
  
const SurveySlackIndex = (props) => {  
    const [sending, setSending] = useState([]);  
    const [onOpen, setOnOpen] = useState(false);  
    const [status, setStatus] = useState('');  
    const [msg, setMsg] = useState('');  
  
    // Acceso a los contextos  
    const msalContext = useContext(MsalContext);  
    // Aquí solo llamamos al custom hook directamente, no usamos useContext sobre él.  
    const { emailContext, isLoggedInTeams, userDataContext} = useEmailContext();  
    
    console.log("******** startg in survey slack index component")
    useEffect(() => {  
        console.log("******** Start in SurveySlackIndex component useEffect, ", msalContext.accounts);  
        
        if (isLoggedInTeams) {  
            console.log("Logged in within Teams:");  
      
            // Check if MSAL accounts are available when logged in via Teams  
            if (msalContext.accounts && msalContext.accounts[0]) {  
                console.log("MSAL Account available:", msalContext.accounts[0]);  
                console.log("Email from context:", emailContext);  
                // Use the localAccountId from MSAL context  
                props.getAllUserSurveys(msalContext.accounts[0].localAccountId);  
            } else if (userDataContext) {  
                // If no MSAL accounts are available, use userDataContext  
                console.log("Using userDataContext to identify the user in Teams:");  
                props.getAllUserSurveys(userDataContext.id);  
            }  
      
        } else if (msalContext.accounts && msalContext.accounts[0]) {  
            // Not inside Teams but MSAL accounts are available  
            console.log("Outside Teams but MSAL account is available:", msalContext.accounts[0]);  
            props.getAllUserSurveys(msalContext.accounts[0].localAccountId);  
      
        } else {  
            // Not in Teams and no MSAL accounts available, fallback to emailContext  
            console.log("Using emailContext to identify the user outside of Teams:");  
            props.getAllUserSurveys(emailContext);  
        }  
    }, [msalContext.accounts, emailContext, isLoggedInTeams, userDataContext, props.getAllUserSurveys]);
    

    const sendSurveyToTeam = async (id) => {  
        let newSending = [...sending];  
        newSending[id] = true;  
        setSending(newSending);  
          
        console.log("Sending state:", sending);  
  
        try {  
            const tenantId = msalContext.accounts[0].tenantId;  
            const res = await axios.get(`https://pulsesurvedeve7ed5fbot.azurewebsites.net/api/notify/${tenantId}/${id}`);  
  
            if (res.status === 200) {  
                setOnOpen(true);  
                setStatus('success');  
                setMsg("Successfully sent survey");  
            } else {  
                setOnOpen(true);  
                setStatus('error');  
                setMsg("An error occurred when sending, please try again");  
            }  
        } catch (error) {  
            console.error("Error sending survey:", error);  
            setOnOpen(true);  
            setStatus('error');  
            setMsg("An error occurred when sending, please try again");  
        }  
  
        newSending[id] = false;  
        setSending(newSending);  
    };  
  
    const getActiveSchedule = (schedules) => {  
        if (schedules.length > 0) {  
            for (let schedule of schedules) {  
                if (schedule.state === true) {  
                    return schedule;  
                }  
            }  
        }  
        return false;  
    };  
  
    const destroySurvey = async (id) => {  
        await props.deleteSurveyTeam(id);  
        if (msalContext.accounts && msalContext.accounts[0]) {  
            await props.getAllUserSurveys(msalContext.accounts[0].localAccountId);  
        }  
    };  
  
    return (  
        <div>  
            {onOpen &&  
                <Alert severity={status} className="alert-style my-3" onClose={() => setOnOpen(false)}>  
                    {msg}  
                </Alert>  
            }  
            <Box>  
                <SurveySlackCard   
                    surveys={props.allSlackSurveys}  
                    status={props.status}  
                    sendSurveyToTeam={sendSurveyToTeam}  
                    deleteSurvey={destroySurvey}  
                    sending={sending}  
                />  
            </Box>  
        </div>  
    );  
};  
  
const mapStateToProps = (state) => {  
    return {  
        allSlackSurveys: state.surveys.allSurveys,  
        singleSlackSurvey: state.surveys.singleSurvey,  
        status: state.surveys.status,  
        selectedTeam: state.users.selectedTeam  
    };  
};  
  
const mapDispatchToProps = (dispatch) => {  
    return {  
        getAllSurveysWithScheduleSlack: (tenantId) => dispatch(getAllSurveysWithScheduleThunk(tenantId)),  
        getSingleSurveySlack: (id) => dispatch(getSingleSurveysWithQandAThunk(id)),  
        deleteSurveyTeam: (id) => dispatch(deleteSurveyThunk(id)),  
        getAllUserSurveys: (userId) => dispatch(getAllUserSurveysThunk(userId))  
    };  
};  
  
export default connect(mapStateToProps, mapDispatchToProps)(SurveySlackIndex);  