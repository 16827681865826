import * as React from 'react';
import {memo, useEffect, useState} from 'react';
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    FormControl,
    IconButton,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/MoodbitLogo.png';
import MenuOption from "./MenuOption";
import MenuUser from "./MenuUser";
import {adminItems} from "../admin-items";
import {NavLink, useNavigate} from "react-router-dom";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import './NavBar.css';
import { getUserThunk} from "../../redux/users/users.thunk";
import { useEmailContext } from '../../redux/EmailContext';

//const pages = ['Dashboard', 'Surveys', 'Reports'];

const styles = {
    logo: {
        width: '180px',
        cursor: 'pointer'
    }
}

const NavBar = memo((props) => {

    const {t} = useTranslation();
    const { isLoggedInTeams, userDataContext} = useEmailContext(); 
    const settings = [
        /*{
            name: 'Profile',
            translation: t('user.profile'),
            route: '/user-profile'
        },
        /*{
            name: 'Account',
            route: '/user-profile'
        },*/
        {
            name: 'Logout',
            translation: t('user.logout'),
            route: '/'
        },
    ];

    const items = adminItems(t);

    console.log("Filtered items: ", items);  
    const isAuthenticated = useIsAuthenticated();

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [team, setTeam] = useState(props.selectedTeam);

    const {accounts, instance, inProgress} = useMsal();

    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (route = null) => {
        setAnchorElNav(null);
        if (route !== null) {
            navigate(route);
        }
    };

    const handleCloseUserMenu = (value = '') => {
        setAnchorElUser(null);
        if (value.name === 'Logout') {
            instance.logoutRedirect({postLogoutRedirectUri: "/"});
            //clear localStorage
            localStorage.removeItem('slackTeamId');
        } else {
            if (value !== '') navigate(value.route)
        }
    };

    const routerLink = (route) => {
        navigate(route);
    }

    const handleTeamChange = (e) => {

        setTeam(e.target.value)
        // props.changeSelectedTeam(e.target.value, props.user)

    }

    useEffect(() => {
        setTeam(props.selectedTeam);

    }, [props.selectedTeam])
    if(isLoggedInTeams && userDataContext){
        return (<></>)
    }
    return (
        <AppBar position="fixed" color="inherit"
                sx={{top: {xs: 'auto', sm: 0}, zIndex: (theme) => theme.zIndex.drawer + 1}}
                className={!isAuthenticated ? inProgress === 'logout' ? '' : 'bg-light' : ''}
                style={{boxShadow: 'none'}}>
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <Typography variant="h6" noWrap component="div" sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}>
                        <img style={styles.logo} src={logo} alt="" onClick={() => routerLink('/')}/>
                    </Typography>

                    {isAuthenticated ?
                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit">
                                <MenuIcon/>
                            </IconButton>
                            <MenuOption anchorElNav={anchorElNav} handleCloseNavMenu={handleCloseNavMenu}/>
                        </Box> : null}
                    <Typography variant="h6" noWrap component="div"
                                sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <img style={styles.logo} src={logo} alt="" onClick={() => routerLink('/')}/>
                    </Typography>
                    {isAuthenticated ? <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {items.map((page, index) => (
                            <NavLink to={page.routerLink} style={{textDecoration: 'none'}} key={index}>
                                <Button variant={page.link ? "text" : "contained"}
                                        color="primary"
                                        sx={{my: 2, color: page.link ? '#4c4b4b' : 'white', display: 'block'}}>
                                    {page.name}
                                </Button>
                            </NavLink>))}
                    </Box> : null}

                    {isAuthenticated && accounts[0] ?
                        <Box sx={{flexGrow: 0}}>
                            {/*props.user.length > 0 ?
                                <Tooltip title="Select your team">
                                    <FormControl sx={{mr: 2, minWidth: 150, display: {xs: 'none', md: 'inline-block'}}} size="small" className="select-nav">
                                        <Select name="team"
                                                id="team"
                                                value={team}
                                                label="Select Your Team"
                                                onChange={(e) => handleTeamChange(e)}>
                                            {props.user.map((us, index) =>
                                                <MenuItem value={us.teamId} key={index}>{us.teamName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Tooltip>
                                : null*/}
                            { !isLoggedInTeams && <Tooltip title={t('user.settings')}>
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                    <Avatar alt={accounts[0].idTokenClaims.extension_firstName}
                                            src="/static/images/avatar/2.jpg"/>
                                </IconButton>
                            </Tooltip>}
                            <MenuUser anchorElUser={anchorElUser}
                                      handleCloseUserMenu={handleCloseUserMenu}
                                      settings={settings}/>
                        </Box> : null}
                </Toolbar>
            </Container>
        </AppBar>
    );
});

const mapStateToProps = (state) => {
    console.log("state Nav", state);
    return {
        user: state.users.user,
        selectedTeam: state.users.selectedTeam,
        selectedAccount: state.users.selectedAccount
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserSlack: (email) => dispatch(getUserThunk(email)),
        // changeSelectedTeam: (teamId, user) => dispatch(changeSelectedTeamThunk(teamId, user))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
