// src/azure_auth/authConfig.js
import { LogLevel } from "@azure/msal-browser";

/**
 * Configuración MSAL
 */
export const msalConfig = {
  auth: {
    // Ajusta a tu App Registration (por ejemplo, si lo sacas de variables de entorno):
    clientId: process.env.REACT_APP_CLIENT_ID || "bed944b9-c7f5-459a-b7af-53c421248893",
    authority: "https://login.microsoftonline.com/common",
    // Ajusta si quieres un redirect distinto:
    redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin,
    navigateToLoginRequestUrl: false
  },
  cache: {
    // Para intentar persistir tokens en localStorage:
    cacheLocation: "localStorage",
    // En algunos casos (IE11/Edge Legacy) ponerlo en true ayuda
    storeAuthStateInCookie: false
  },
  system: {
    // Permite login en iframe (necesario para Teams)
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error:
            console.error("MSAL ERROR =>", message);
            break;
          case LogLevel.Warning:
            console.warn("MSAL WARN =>", message);
            break;
          case LogLevel.Info:
            console.info("MSAL INFO =>", message);
            break;
          case LogLevel.Verbose:
            console.debug("MSAL VERBOSE =>", message);
            break;
          default:
            break;
        }
      },
    }
  }
};

/**
 * Scopes a solicitar al iniciar sesión
 */
export const loginRequest = {
  scopes: ["User.Read"] // Podrías agregar más si tu backend u otras APIs lo requieren
};

/**
 * Config para MS Graph
 */
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
