// src/azure_auth/callMsGraph.js
import { graphConfig } from "./authConfig";

/**
 * Envía una solicitud a MS Graph con el accessToken dado
 * Retorna la respuesta en JSON (ej. datos del perfil).
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${accessToken}`);

  const response = await fetch(graphConfig.graphMeEndpoint, {
    method: "GET",
    headers
  });

  if (!response.ok) {
    throw new Error(`Graph call failed. Status: ${response.status}`);
  }

  return response.json();
}
