// src/azure_auth/authConfig.js
import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    // Ajusta a tu App Registration (Multi-tenant) en Azure
    clientId: process.env.REACT_APP_CLIENT_ID, 
    authority: "https://login.microsoftonline.com/common",
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    // Para evitar bucles de redirección en MS Teams, 
    // configúralo en false:
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", // or "sessionStorage"
    // En Edge Legacy o IE11, podría ser true.
    storeAuthStateInCookie: false,
  },
  system: {
    allowRedirectInIframe: true, 
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            break;
        }
      },
    },
  },
};

/**
 * Scopes que se pedirán durante el login. 
 * Por defecto OIDC ya incluye: openid, profile, email
 */
export const loginRequest = {
  scopes: ["User.Read"], 
};

/**
 * Opcional: endpoint para MS Graph
 * (lo usas en callMsGraph)
 */
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
