import React, { createContext, useState, useContext } from "react";  
  
const EmailContext = createContext();  
  
export const useEmailContext = () => {  
    return useContext(EmailContext);  
};  
  
export const EmailProvider = ({ children }) => {  
    const [emailContext, setEmailContext] = useState("");  
    const [isLoggedInTeams, setIsLoggedInTeams] = useState(false); // Nuevo estado  
    const [userDataContext, setUserDataContext] = useState(null); // Nuevo estado
    return (  
        <EmailContext.Provider value={{ emailContext, setEmailContext, 
            isLoggedInTeams, setIsLoggedInTeams,
            userDataContext, setUserDataContext
          }}>  
            {children}  
        </EmailContext.Provider>  
    );  
};  